const summary = resolve => require(["../views/summary/summary.vue"], resolve);

export default [
  {
  		path: "/summary",
  		name: "summary",
  		component: summary,
  		meta: {
  			headerShow: true, //顶部状态栏是否显示
  			menuShow: true, //顶部菜单是否显示
  			menuType: 1,//顶部菜单类型
  		}
  	}, // 报警管理
]