const alarm = resolve => require(["../views/alarm/vibration/alarm.vue"], resolve); //振动报警
const deformationAlarm = resolve => require(["../views/alarm/deformation/index.vue"], resolve); //变形报警

export default [{
		path: "/alarm",
		name: "alarm",
		component: alarm,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 振动报警管理
	{
		path: "/deformationAlarm",
		name: "deformationAlarm",
		component: deformationAlarm,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 变形报警管理
]
