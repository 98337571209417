import Vue from 'vue';
import Axios from 'axios';
import qs from 'qs';
import router from "../router";

Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
Axios.defaults.withCredentials = true;
Axios.defaults.baseURL = Vue.prototype.constData.baseURL;

//请求开始
Axios.interceptors.request.use(config => {
	// // console.log(config)
	Vue.prototype.loadingAnimation.hide();
	var txt = config.url;
	// 记录每个接口遇到401重新请求的数据（请求正常时重置）
	if(sessionStorage[txt] == undefined){
		sessionStorage[txt] = 0;
	}

	var rmtk = localStorage.getItem('lInfo');
	
	if (rmtk != null && rmtk != '' && rmtk != undefined) {
		config.headers['rmtk'] = rmtk;
	}
	
	if (config.method === 'get') {
		config.params ? config.params : config.params = {};

		if(config.params.hasOwnProperty('responseType')){
			config['responseType'] = config.params.responseType;
			delete config.params.responseType;
		}

		if (config.params.loading === undefined || config.params.loading)
			Vue.prototype.loadingAnimation.show('','el-icon-loading');

		//如果data中包含加载动画,从data中删除加载动画字段'loading'
		delete config.params.loading;
	} else {
		//判断请求是否包含不显示加载动画字段
		config.data ? config.data : config.data = {};

		if (config.data.loading === undefined || config.data.loading)
			Vue.prototype.loadingAnimation.show('','el-icon-loading');

		//如果data中包含加载动画,从data中删除加载动画字段'loading'
		delete config.data.loading;
	}

	if (!(config.data instanceof FormData)) {
		config.data ? config.data : config.data = {};
		if (config.method !== 'get') {
			for (let i in config.data) {
				if (config.data[i] || config.data[i] === 0 || config.data[i] === -1) {
					//删除上传字段中的空格
					let str = typeof config.data[i] === "string" ? config.data[i].replace(/-/g, "/") : config
						.data[i];
					if (typeof config.data[i] === "string" && isNaN(Date.parse(str))) {
						// // console.log(config.data[i])
						config.data[i] = config.data[i].toString().replace(/(^\s*)|(\s*$)/g,"");
					}
				} else {
					//删除值为空字段
					delete config.data[i]
				}
			}
			config.data = qs.stringify(config.data);
			
		}
	}
// // console.log(config)
	return config;
}, error => {
	Vue.prototype.loadingAnimation.hide();
	Vue.prototype.$message({
		showClose: true,
		message:'请求失败',
		type:'error'
	});
	return Promise.reject(error);
});

//请求结束
Axios.interceptors.response.use(resp => {
	Vue.prototype.loadingAnimation.hide();
	
	if(resp.headers.hasOwnProperty('access-head')){
		localStorage.setItem('lInfo',resp.headers['access-head']);
	}
	
	// // console.log(resp);
	var txt = resp.config.url;
	if (resp.data.status === false && resp.data.responseCode == 401) {
		if(Number(sessionStorage[txt]) < 1){
			sessionStorage[txt] = 1;
			Axios(resp.config);
			return resp.data
		}
		Vue.prototype.$message({
			showClose: true,
			message:"登录过期，请重新登录",
			type:'error'
		});
		router.push({
			name:'login'
		})
	} else if (resp.data.status == false) {
		Vue.prototype.$message({
			showClose: true,
			message:resp.data.message,
			type:'error'
		});
	}
	if(resp.data.responseCode != 401){
		sessionStorage[txt] = 0;
	}
	// // console.log(resp);
	// // console.log(typeof resp.data)
	if(typeof resp.data == "object"){
		resp.data.headers = resp.headers;
	}
	return resp.data;
}, error => {
	Vue.prototype.loadingAnimation.hide();
	Vue.prototype.$message({
		showClose: true,
		message:error.message,
		type:'error'
	});
	return Promise.reject(error);
});

export default Axios
