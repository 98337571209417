<!--分页模块-->
<template>
  <div :class=" 'tableFooter no-select-text '+ item.type+'Page' ">
    <ul class="pageNumber">
      <li class="prev el-icon-arrow-left" @click="nextPage($event, false, item)" v-show="item.allPage>1"></li>
      <li :class="i === item.page ? 'active item' : 'item'" @click="nextPage($event, i, item)" v-for="i in item.allPage"
          v-show="getPageListNumbers(i, item)!==undefined" :key="i">{{getPageListNumbers(i, item)}}
      </li>
      <li class="next el-icon-arrow-right" @click="nextPage($event, true, item)" v-show="item.allPage>1"></li>
    </ul>
    <div class="gotoPage">
      到第<input type="number" :data-old="item.page" :value="item.page" placeholder="">页
      <span class="button" @click="gotoPage($event, item)">确定</span>
      共{{item.total}}条
    </div>
  </div>
</template>

<script>
  export default {
    name: "pagination",

    props: {
      currentPageChange: {
        type: Function,
        required: true
      },
      item: Object
    },

    methods: {
      nextPage(e, obj, item) {
        var _this = $(e.currentTarget);
        item.page = Number(item.page);
        item.allPage = Number(item.allPage);
        if (obj === true) {
          if (item.page < item.allPage) item.page += 1;
          else return false
        } else if (obj === false) {
          if (item.page > 1) item.page -= 1;
          else return false
        } else {
          item.page = Number(obj);
        }
		if (_this.hasClass('active')) return false;
        this.currentPageChange(item.type)
      },
      gotoPage(e, item) {
        let val = $(e.currentTarget).parent().find('input').val();
        const old = $(e.currentTarget).parent().find('input').attr('data-old');
        val = Math.abs(val);
        if (old !== val && val <= item.allPage) {
          item.page = val;
          this.currentPageChange(item.type)
        }
      },
      getPageListNumbers(i, item) {
        i = Number(i);
        item.page = Number(item.page);
        item.allPage = Number(item.allPage);
        if (i === 1) {
          return i;
        } else if (i === item.allPage) {
          return item.allPage;
        } else {
          if (item.page === 1 || item.page === item.allPage) {
            for (let l = 0; l < 6; l++) {
              if (item.page + l === i || item.page - l === i) {
                return i;
              }
            }
          } else if (item.page === 2 || item.page === item.allPage - 1) {
            for (let f = 0; f < 5; f++) {
              if (item.page + f === i || item.page - f === i) {
                return i;
              }
            }
          } else if (item.page === 3 || item.page === item.allPage - 2) {
            for (let x = -1; x < 4; x++) {
              if (item.page + x === i || item.page - x === i) {
                return i;
              }
            }
          } else if ((Math.abs(i - item.page)) < 3) {
            return i;
          }
        }
      }
    },
  }
</script>

<style scoped>
  .tableFooter {
    width: 100%;
    height: 48px;
    flex: 0 0 48px;
    background-color: #f2f2f2;
    /*border-top: 1px solid #e2e2e2;*/
  }

  .tableFooter .pageNumber {
    float: left;
    overflow: hidden;
    height: 30px;
    margin: 9px 5px;
  }

  .tableFooter .pageNumber li {
    float: left;
    min-width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    overflow: hidden;
    color: #51B3FE;
    margin-right: 5px;
    border-radius: 2px;
  }

  .tableFooter .pageNumber li.item {
    cursor: pointer;
    font-family: 'arial', serif;
    font-size: 12px;
    width: auto !important;
  }

  .tableFooter .pageNumber li.prev,
  .tableFooter .pageNumber li.next {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tableFooter .pageNumber li.prev.disabled img:first-child {
    display: inline-block;
  }

  .tableFooter .pageNumber li.prev.disabled img:last-child {
    display: none;
  }

  .tableFooter .pageNumber li.next.disabled img:first-child {
    display: inline-block;
  }

  .tableFooter .pageNumber li.next.disabled img:last-child {
    display: none;
  }

  .tableFooter .pageNumber li.prev:hover,
  .tableFooter .pageNumber li.next:hover,
  .tableFooter .pageNumber li.item:hover {
    background-color: #dfdfdf;
  }

  .tableFooter .pageNumber li.active {
    background-color: #51B3FE;
    color: #fff;
  }

  .tableFooter .pageNumber li.active:hover {
    background-color: #4aa4e9;
  }

  .tableFooter .gotoPage {
    float: left;
    height: 26px;
    line-height: 26px;
    margin-top: 11px;
  }

  .tableFooter .gotoPage span.button {
    height: 26px;
    width: 50px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #51B3FE;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 5px;
    border-radius: 2px;
    cursor: pointer;
    background-color: #51B3FE;
    color: #fff;
  }

  .tableFooter .gotoPage span.button:hover {
    border-color: #4aa4e9;
    background-color: #4aa4e9;
  }

  .tableFooter .gotoPage input {
    width: 50px;
    text-indent: 0;
    text-align: center;
    margin: 0 5px;
    line-height: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #51B3FE;
    color: #51B3FE;
  }

  .tableFooter .gotoPage input:hover {
    border-color: #4aa4e9;
    color: #4aa4e9;
  }

  .tableFooter .mySelect .dropDown p:hover {
    background-color: #eee;
  }
</style>
