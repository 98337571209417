const dataManagement = resolve => require(["../views/dataManagement/vibration/index.vue"], resolve); //振动数据
const deformationData = resolve => require(["../views/dataManagement/deformation/index.vue"], resolve); //变形数据
const deformationDataCustom = resolve => require(["../views/dataManagement/deformationCustom/index.vue"], resolve); //变形数据定制
export default [{
		path: "/dataManagement",
		name: "dataManagement",
		component: dataManagement,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 数据管理
	{
		path: "/deformationData",
		name: "deformationData",
		component: deformationData,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 变形数据管理
	{
		path: "/deformationDataCustom",
		name: "deformationDataCustom",
		component: deformationDataCustom,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 变形数据管理定制
]
