import selectDate from './selectDate'
import uploadImages from './uploadImages'
import noData from './noData'
import button from './button'
import tableHeader from './tableHeader'
import Pagination from './pagination'
import layerContainer from './layerContainer'
import layerItem from './layerItem'

export default (Vue) => {
    Vue.component("SelectDate", selectDate);
    Vue.component('UploadImages', uploadImages);
    Vue.component('NoData', noData);
    Vue.component('Button', button);
    Vue.component('Pagination', Pagination);
    Vue.component('LayerContainer', layerContainer);
    Vue.component('LayerItem', layerItem);
    Vue.component('TableHeader', tableHeader);
}
