<template>
	<div class="file-box">
		<el-upload
			action=""
			list-type="picture-card"
			:multiple="configuration.limit > 1 ? true : false"
			:limit="configuration.limit"
			:file-list="fileList"
			:auto-upload="true"
			:accept="type"
			:on-exceed="beyond"
			:on-change="setFile"
			:before-upload="examination"
			:http-request="httpUpload"
			:class="configuration.name"
			:ref="configuration.name"
		>
			<i slot="default" class="el-icon-plus"></i>
			<div slot="file" slot-scope="{ file }">
				<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
				<span class="el-upload-list__item-actions">
					<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)"><i class="el-icon-zoom-in"></i></span>
					<!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)"><i class="el-icon-download"></i></span> -->
					<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)"><i class="el-icon-delete"></i></span>
				</span>
			</div>
		</el-upload>
		<!-- <el-dialog :modal-append-to-body="false" :visible.sync=""><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog> -->
		<!-- 图片预览 -->
		<el-image-viewer v-if="dialogVisible" :on-close="closeViewer" :url-list="[dialogImageUrl]" />
	</div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
	name: 'uploadImg',
	props: {
		configuration: {
			type: Object,
			default: () => {
				return {
					limit: 1, //最大文件个数
					name: 'file' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
				};
			}
		}
	},
	data() {
		return {
			fileList: [],
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			cleared: false, // 是否清除过图片
			type: 'image/gif,image/jpeg,image/pipeg,image/svg+xml,image/png' //文件类型  .pdf, .doc, .docx, .xls, .xlsx .jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF  详细MIME类型 看https://zhuanlan.zhihu.com/p/268822410
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取上传文件
		getFile() {
			return this.fileList;
		},
		// 清除文件
		clearFile() {
			// // console.log(this.$refs[this.configuration.name])
			this.$refs[this.configuration.name].clearFiles();
			this.fileList = [];
			this.cleared = false;
			this.hideFile();
		},
		// 文件上传数据赋值
		setFile(file, fileList) {
			this.fileList = fileList;
			this.hideFile();
			// // console.log(fileList)
		},
		// 设置预览图片
		setFileData(url) {
			var time = new Date().getTime();
			this.fileList = [
				{
					name: time + '.jpg',
					url: url
				}
			];
			this.hideFile();
		},
		// 是否隐藏文件上传框
		hideFile() {
			// // console.log(this.fileList)
			if (this.fileList.length >= this.configuration.limit) {
				$('.' + this.configuration.name)
					.find('.el-upload--picture-card')
					.css({
						display: 'none'
					});
			} else {
				$('.' + this.configuration.name)
					.find('.el-upload-list__item')
					.css({
						display: 'none'
					});
				$('.' + this.configuration.name)
					.find('.el-upload--picture-card')
					.css({
						display: 'inline-block'
					});
			}
		},
		// 检查上传文件
		examination(file) {
			// // console.log("文件")
			console.log(file);
			// // console.log(this.fileList)
			let arr = this.type.split(',');
			if(arr.length == 0){
				return true
			}
			if (arr.includes(file.type)) {
				let len = this.fileList.length;
				if (len > 1) {
					for (let i = 0; i < len; i++) {
						let item = this.fileList[i];
						if (item.name == file.name) {
							this.$message({
								showClose: true,
								message: '请不要上传相同文件',
								type: 'warning'
							});
							return false;
						} else {
							return true;
						}
					}
				} else {
					return true;
				}
			} else {
				this.$message({
					showClose: true,
					message: '请上传png，jpg，gif，jpeg，svg类型的图片',
					type: 'warning'
				});
				return false;
			}
		},
		// 自定义上传行为
		httpUpload() {
			return false;
		},
		// 删除
		handleRemove(file) {
			var len = this.fileList.length;
			for (let i = 0; i < len; i++) {
				if (this.fileList[i].name == file.name) {
					this.fileList.splice(i, 1);
					break;
				}
			}
			this.cleared = true;
			this.hideFile();
			// // console.log(file);
		},
		// 预览
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		// 关闭预览
		closeViewer() {
			this.dialogVisible = false;
		},
		// 获取是否删除图片判断数据
		getJudgeData() {
			return this.cleared;
		},
		// 超出最大文件数
		beyond(files, fileList) {
			// // console.log(fileList)
			this.$message({
				showClose: true,
				message: `所选文件数超出最大限制数（${this.configuration.limit}个），请重新选择！`,
				type: 'warning'
			});
		},
		// 获取配置参数
		getConfiguration() {
			return this.configuration;
		}
	},
	components: {
		ElImageViewer
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.file-box {
	::v-deep {
		.el-upload-list__item {
			transition: none !important;
		}
	}
}
</style>
