<!--n个弹出框中的一层-->
<template>
	<div :class="'layerItem ' + param.itemClass">
		<i class="cancel" @click="closepoint($event)" v-if="!flagss">✕</i>
		<div class="layerContainer">
			<div class="layerHeader no-select-text">
				<span class="title">{{ param.title }}</span>
			</div>
			<div class="layerContent"><slot /></div>
			<div class="layerFooter no-select-text" v-if="(param.hiddenConfirm && param.hiddenCancel) === false">
				<p class="hint" :style="'color:' + param.hintColor">{{ param.hintText }}</p>
				<p class="buttonBox">
					<Button
						v-if="!param.hiddenConfirm"
						class="confirm"
						width="90"
						height="30"
						:text="param.confirmBtn"
						:type="param.confirmType"
						:buttonCallback="buttonCallback"
					/>
					<!-- <Button v-if="!param.hiddenCancel" class="cancel" width="90" height="30" :text="param.cancelBtn" :type="param.cancelType" :buttonCallback="buttonCallback"/> -->
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'layerItem',
	props: {
		layerBtnClick: {
			type: Function,
			required: false
		},
		layerParam: Object
	},
	data() {
		return {
			flagss: false
		};
	},
	methods: {
		closepoint(e) {
			$('.pointChecks').removeClass('show');
			this.buttonCallback('stationCan', e);
		},
		buttonCallback(type, e) {
			if (typeof this.layerBtnClick === 'function') {
				this.layerBtnClick(type, e);
			}
			if (e.target.className === 'cancel') {
				$('.layerContainerBox').fadeOut(300);
			}
		}
	},
	mounted() {
		this.flagss = false;
	},
	computed: {
		param() {
			// 设置默认值
			return Object.assign(
				{
					title: '添加用户',
					itemClass: '',
					confirmBtn: '确定',
					cancelBtn: '取消',
					confirmType: 'userConfirm',
					cancelType: 'userCancel',
					hintText: '',
					hintColor: '#f00',
					hiddenCancel: false,
					hiddenConfirm: false
				},
				this.layerParam
			);
		}
	},
	watch: {
		layerParam: {
			//监听的对象
			deep: true, //深度监听设置为 true
			immediate: true, //允许第一次赋值默认值
			handler(value) {
				this.title = value.title ? value.title : 'title'; //弹出框标题
				this.confirmBtn = value.confirmBtn ? value.confirmBtn : '确定'; //确定按钮文字
				this.cancelBtn = value.cancelBtn ? value.cancelBtn : '取消'; //取消按钮文字
				this.confirmType = value.confirmType ? value.confirmType : 'confirm'; //确定按钮类型
				this.cancelType = value.cancelType ? value.cancelType : 'cancel'; //取消按钮类型
				this.hintText = value.hintText ? value.hintText : ''; //验证时的提示文字
				this.hintColor = value.hintColor ? value.hintColor : '#f00'; //验证时的提示文字颜色
				this.hiddenConfirm = value.hiddenConfirm ? value.hiddenConfirm : false; //是否隐藏确定按钮
				this.hiddenCancel = value.hiddenCancel ? value.hiddenCancel : false; //是否隐藏取消按钮
			}
		}
	}
};
</script>

<style scoped>
.layerItem {
	width: 600px;
	max-height: calc(100vh * 0.8);
	position: relative;
	background-color: #fff;
	display: none;
}
.cancel {
	display: block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	position: absolute;
	top: 12px;
	right: 12px;
	text-align: center;
	cursor: pointer;
	z-index: 9;
	color: #fff;
	font-size: 20px;
}
.layerContainer {
	width: 100%;
	min-height: 200px;
	/*max-height: calc(100vh * 0.8 - 50px);*/
	display: flex;
	flex-flow: column;
	position: relative;
	align-content: stretch;
	z-index: 2;
	overflow: hidden;
}

.layerHeader {
	height: 45px;
	flex: 0 0 45px;
	line-height: 45px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	position: relative;
	/* background-color: #3b9eff; */
	background-color: #609dbf;
}

.layerHeader .title {
	display: inline-block;
	color: #fff;
	width: 100%;
	text-align: center;
	line-height: 45px;
	font-size: 14px;
	letter-spacing: 5px;
	text-indent: 5px;
}

.layerFooter {
	flex: 0 0 30px;
	height: 30px;
	line-height: 30px;
	overflow: hidden;
	margin-bottom: 10px;
}

.layerFooter p.hint {
	float: left;
	color: #f20000;
	text-indent: 20px;
}

.layerFooter p.buttonBox {
	/* float: right; */
	overflow: hidden;
}

.layerFooter p.buttonBox button {
	/* margin-right: 160px; */
	margin: 0 auto;
	display: block;
	letter-spacing: 5px;
	text-indent: 5px;
}

.layerFooter p.buttonBox button.cancel {
	background-color: #ccc;
	border-color: #ccc;
}

.layerContent {
	flex: 1 1 auto;
	max-height: calc(100vh * 0.8 - 135px);
	padding: 20px 0;
	-overflow: hidden;
	position: relative;
	display: flex;
	flex-flow: column;
}
</style>
