// 数据相关页面下载excel
import Vue from "vue";
import XlsxPopulate from 'xlsx-populate';

// table: 表头和表格数据
// tableName: 表名称
// width: 表每列宽度


Vue.prototype.tableDownloadExcel = function(table = [], tableName = '', width = []) {
	if (table.length == 1) {
		this.$message.error('生成失败！数据为空');
		return;
	}
	// // console.log(table)
	// 文档名称
	var timestamp = (new Date()).format('yyyy-MM-dd hh_mm_ss');
	var letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S",
		"T", "U", "V", "W", "X", "Y", "Z"
	];
	var widthLen = width.length;
	var heard = table[0];
	var heardLen = heard.length;
	var list = table[1];
	// // console.log(list)
	var listLen = list.length;
	XlsxPopulate.fromBlankAsync()
		.then(workbook => {
			// 第一个sheet页
			let wb = workbook.sheet("Sheet1");
			// 设置列宽
			for(let i = 0; i<widthLen;i++){
				wb.column(`${letter[i]}`).width(width[i]);
			}
			
			// 设置行高
			wb.row(1).height(30);
			// 标题合并单元格  A列到L列,1行到2行合并
			wb.range(`A1:${letter[heardLen-1]}1`).merged(true)
				.value(tableName)
				.style("verticalAlignment", "center") //垂直居中
				.style("horizontalAlignment", "center") //水平位置
				.style("border", true)
				.style("fontSize", 18);
			for (let i = 0; i < heardLen; i++) {
				// 表头
				wb.cell(`${letter[i]+'2'}`)
					.value(`${heard[i]}`)
					.style("border", true)
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "center"); //水平位置
			}
			for (let i = 0; i < listLen; i++) {
				// 内容
				let n = list[i];
				let index = 2 + n.id;
				let num = 0;
				for (let key in n) {
					if(key != 'id'){
						wb.cell(`${letter[num]+index}`)
							.value(`${n[key]}`)
							.style("border", true)
							.style("verticalAlignment", "center") //垂直居中
							.style("horizontalAlignment", "center"); //水平位置;
							num += 1;
					}
				}
			}

			// Write to file.
			workbook.outputAsync().then(function(blob) {
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					// If IE, you must uses a different method.
					window.navigator.msSaveOrOpenBlob(blob, timestamp + '.xlsx');
				} else {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					document.body.appendChild(a);
					a.href = url;
					a.download = timestamp + '.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
				}
			})
		});
}
