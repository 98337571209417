import Vue from "vue";
Vue.prototype.constData = {

	// baseURL:'http://121.36.195.235:8080/',//测试服务器
	// imgUrl: 'http://121.36.195.235:8080/', //图片地址

	// baseURL: 'http://192.168.1.20:8080/', //本地测试
	// imgUrl: 'http://192.168.1.20:8080/', //图片地址

	// baseURL:'http://192.168.50.7:8080/',//本地测试
	// imgUrl: 'http://192.168.50.7:8080/',//图片地址

	baseURL: "https://ejiaobo.cn/api/", // 线上地址
	imgUrl: "https://ejiaobo.cn/api/", // 线上图片地址

	// baseURL: "http://localhost:8080/", // 线上地址
	// imgUrl: "https://localhost:8080/", // 线上图片地址

	colorFileUrl: '/diagramColor.json', // 抽样数据曲线图颜色文件地址(本地public文件夹)

	pageUrlOne: [
		// {
		// 	id: 1,
		// 	path: 'index',
		// 	tips: '首页',
		// },
		{
			id: 32,
			path: 'point', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '测点',
		},
		{
			id: 47,
			path: 'dataManagement', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '数据',
		},
		{
			id: 48,
			path: 'reportManagement', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '报告',
		},
		{
			id: 49,
			path: 'alarm', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '报警',
		},
		{
			id: 50,
			path: 'event', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '事件',
		},
		{
			id: 65,
			path: 'summary', //振动菜单
			deformationShow: false, //变形菜单是否显示
			tips: '总结',
		},
		{
			id: 107,
			path: 'deformationPoint', //变形菜单
			deformationShow: true, //变形菜单是否显示
			tips: '测点线',
		},
		{
			id: 108,
			path: 'deformationData', //变形菜单
			deformationShow: true, //变形菜单是否显示
			tips: '数据',
		},
		{
			// 定制变形页面
			id: 113,
			path: 'deformationDataCustom', //变形菜单
			deformationShow: true, //变形菜单是否显示
			tips: '数据',
		},
		{
			id: 109,
			path: 'deformationReport', //变形菜单
			deformationShow: true, //变形菜单是否显示
			tips: '报告',
		},
		{
			id: 110,
			path: 'deformationAlarm', //变形菜单
			deformationShow: true, //变形菜单是否显示
			tips: '报警',
		},
	], //项目内部路由配置
	pageUrlTwo: [{
			id: 53,
			path: 'vibrationEquipment',
			tips: '振动设备',
		},
		{
			id: 95,
			path: 'deformationEquipment',
			tips: '变形设备',
		},
		{
			// 定制变形页面
			id: 114,
			path: 'deformationEquipmentCustom',
			tips: '变形设备',
		},
		{
			id: 51,
			path: 'user',
			tips: '用户',
		},
		{
			id: 52,
			path: 'role',
			tips: '角色',
		},
		{
			id: 54,
			path: 'tool',
			tips: '工具',
		},
	], //平台管理路由配置
	toolRouter: [{
			type: "管理",
			menu: [{
					icon: 'icon-guanli',
					name: '转发管理',
					pathName: "forwardingInfo",
					id: 66,
				},
				{
					icon: 'icon-shebeiliebiao',
					name: '设备列表',
					pathName: "equipmentList",
					id: 67
				},
				{
					icon: 'icon-shebeiliebiao',
					name: '监测公司',
					pathName: "monitoringCompany",
					id: 42
				},
				{
					icon: 'icon-shebeiliebiao',
					name: '监管项目',
					pathName: "regulatoryItems",
					id: 46,
				},
				{
					icon: 'icon-admin-manage',
					name: '公司管理员',
					pathName: "administrator",
					id: 86
				},
				{
					icon: 'icon-admin-manage',
					name: '数据分析',
					pathName: "analysis",
					id: 68
				},
				{
					icon: 'icon-_xiangmuguanli',
					name: '项目业务',
					pathName: "project",
					id: 92
					// id: 68
				},
				{
					icon: 'icon-shebeiguanli',
					name: '设备管理',
					pathName: "equipment",
					id: 93
					// id: 68
				},
			]
		},
		{
			type: "管理",
			menu: [{
				icon: 'icon-guanli',
				name: '用户操作日志',
				pathName: "userActionLog",
				id: 117,
				color: '#409EFF'
			}]
		},
	], //工具页面路由
	samplingRate: ['100K', '50K', '10K', '5K', '2K', '1K'], // 采样率(sps)
	interval: [700, 60, 30, 10, 2], // 采集间隔
	sensorType: [{
			name: '速度传感器（cm/s）',
			unit: 'cm/s',
			id: 0
		},
		{
			name: '加速度传感器（g）',
			unit: 'g',
			id: 1
		},
		{
			name: '速度传感器（mm/s）',
			unit: 'mm/s',
			id: 3
		},
	], // 传感器类型
}