<template>
	<div class="flie-page" v-if="previewStatus">
		<div class="flie-setup-box">
			<!-- 报告预览 -->
			<div class="preview-box Mask-box">
				<div class="pop-ups-content box-center">
					<div class="mian-box">
						<div class="left-box"><iframe frameborder="0" scrolling="auto" :src="src" width="1120px" height="100%" /></div>
						<div class="right-box">
							<el-button size="small" @click="saveFlie(PSData)">
								<div class="but-content">
									<span class="icon iconfont icon-baocun-tianchong"></span>
									<span>保存</span>
								</div>
							</el-button>
							<el-button size="small" @click="cancel">
								<div class="but-content">
									<span class="icon iconfont el-icon-close close"></span>
									<span>关闭</span>
								</div>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		// 预览报告
		view: {
			type: String,
			default: 'web2/data/odnr/viewrp'
		},
		// 保存报告
		saveUrl: {
			type: String,
			default: 'web2/data/dmf/save'
		},
		// 保存报告是否调用外部函数
		jump:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			ids: [], //传递过来的选中的文件id
			fileInfo: {},
			fileName: '',
			previewStatus: false, // 是否预览报告
			src: '', //报告预览地址
			parameter: {}, // 报告参数
			PSData: {}, //保存、预览报告参数
			ops: {
				bar: {
					onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
					keepShow: true //滚动条一直显示
				}
			} //滚动条设置
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 预览
		preview(val) {
			this.PSData = val;
			// console.log(val);
			this.previewStatus = false;
			this.axios.post(this.view, val, { responseType: 'arraybuffer' }).then(res => {
				// // console.log(res);
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);
				
				if(res.byteLength == 0){
					this.$message({
						message: '暂无pdf数据！',
						type: 'warning'
					});
					return
				}

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					this.src = href;
					this.previewStatus = true;
					// $('.flie-page').css({
					// 	zIndex: '1000'
					// });
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
					this.$emit('cancel')
				}
			});
		},

		// 保存
		saveFlie(val) {
			if(!this.jump){
				this.axios.post(this.saveUrl, val).then(res => {
				// // console.log(res);
				if (res.status) {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
				}
			});
			}else{
				this.$emit('save');
			}
		},
		// 关闭预览
		cancel() {
			this.$emit('shutDown');
			this.previewStatus = false;
			this.close('', true);
			this.$emit('cancel')
		},
		// 关闭弹窗
		close(val, status = true) {
			if (val != '') {
				$('.' + val).hide();
			}
			if (status) {
			}
		},
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.flie-page {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;

	.flie-setup-box {
		width: 100%;
		height: 100%;
		position: relative;

		.width-s {
			width: 100px !important;
		}

		// 报告设置
		.set-up {
			z-index: 1000;
			display: none;

			// 弹窗标题
			.pop-ups-title {
				// width: 100%;
				// height: 35px;
				// line-height: 35px;
				// font-size: 14px;
				// text-align: center;
				// background-color: #66b1ff;
				// color: #ffffff;
				// position: relative;

				.close {
					display: inline-block;
					width: 15px;
					height: 15px;
					position: absolute;
					top: 0px;
					bottom: 0;
					margin: auto;
					right: 15px;
					color: #ffffff;
					font-size: 14px;
				}
			}

			.pop-ups-content {
				width: 600px;
				height: 585px;
				background-color: #ffffff;

				.mian-box {
					width: calc(100% - 30px);
					height: calc(100% - 120px);
					padding: 15px;

					.cell-box {
						width: 100%;
						display: flex;
						// flex-direction: column;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 12px;

						.title {
							width: 70px;
							height: 30px;
							line-height: 30px;
							display: inline-block;
							font-size: 13px;
							// margin-bottom: 5px;

							i {
								color: #f20000;
								font-size: 12px;
							}
						}

						.title-s {
							width: 45px;
						}

						::v-deep {
							.el-input {
								width: calc(100% - 70px);
							}
							.el-select {
								width: calc(100% - 70px);
							}
							.el-select .el-tag {
								max-width: 450px;
								padding-right: 20px;
								height: auto;
								white-space: pre-wrap;
								position: relative;
							}
							.el-tag .el-tag__close {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								margin: auto;
							}
							.el-select .el-input {
								width: 100%;
							}
							.el-textarea {
								width: calc(100% - 70px);
							}
							.el-input__inner {
								width: 100%;
								height: 35px;
							}
						}

						.operation-box {
							border-left: thin solid #909399;
							margin-left: 20px;
							padding-left: 10px;
							display: flex;
							align-items: center;

							>>> .el-switch__label * {
								font-size: 12px;
							}

							.operation-name {
								font-size: 13px;
								margin-right: 10px;
							}
						}

						.content {
							height: 35px;
							line-height: 35px;
						}
					}

					.but-box {
						width: 196px;
						margin: 15px 0;
						display: flex;
						justify-content: center;
						align-items: center;
						float: right;
					}
				}
			}
		}
		// 报告预览
		.preview-box {
			// display: none;
			z-index: 10000;

			.pop-ups-content {
				width: 1190px;
				height: 100%;

				.mian-box {
					width: 100%;
					height: 98%;
					display: flex;
					margin-top: 10px;

					.left-box {
						height: 100%;
					}

					.right-box {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;

						.el-button {
							padding: 0;
							width: 56px;
							height: 56px;
							margin-left: 14px;
							margin-bottom: 15px;
						}

						.but-content {
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							align-items: center;

							.icon {
								color: #409eff;
								font-size: 22px;
							}
							
							.close{
								font-size: 22px;
								font-weight: 600;
								color: #d80000;
							}

							.iconhoutaitubiao-25 {
								font-size: 35px !important;
							}

							span:last-child {
								color: #000;
							}
						}
					}
				}
			}
		}
	}
}
</style>
