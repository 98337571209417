<template>
	<div class="historyPage">
		<HistorySta :switchNavMenu="switchNavMenu" :authorityList="authorityList" ref="HistorySta" v-show="page == 'HistorySta'"></HistorySta>
		<HistoryExp :switchNavMenu="switchNavMenu" :authorityList="authorityList" ref="HistoryExp" v-show="page == 'HistoryExp'"></HistoryExp>
		<!--  简报预览  -->
		<previewChart ref="previewChart" v-if="preview" @associateData="pagechange('HistoryExp', $event)" />
		<!-- 报表设置弹窗 -->
		<div class="setup-report"><setupReport ref="setupReport" view="web2/upblas/mmo/vrp" @save="setThePeriods" @cancel="closeDom"></setupReport></div>
		<!-- 生成报告设置弹窗 -->
		<el-dialog title="设置报告期数" :visible.sync="dialogFormVisible" custom-class="setupNper" :append-to-body="true" :modal-append-to-body="false" :show-close="false" center>
			<div class="setup-input">
				<span>第</span>
				<el-input v-model="term" placeholder=""></el-input>
				<span>期</span>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
				<el-button size="small" type="primary" @click="saveReport()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import HistoryExp from './historyExp/historyExp.vue';
import HistorySta from './historySta/historySta.vue';
import previewChart from '../../components/previewChart.vue';
import setupReport from '../../components/setupReport_r.vue';

export default {
	name: 'history',
	props: {
		// 按钮权限
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	components: {
		HistoryExp,
		HistorySta,
		previewChart,
		setupReport
	},
	data() {
		return {
			page: 'HistorySta',
			pageType: '',
			preview: false, // 简报预览是否加载
			fileBaseData: {}, //简报数据
			pointId: null, //测点id
			dialogFormVisible: false, // 生成报告设置弹窗
			term: null, //报告期数
			saveData: {} ,//保存报告参数
			eventData: null,// 事件列表当前点击行数
		};
	},
	mounted() {},
	methods: {
		// 获取数据
		setList(parmas) {
			this.pointId = parmas.id;
			this.eventData = parmas.e;
			this.page = 'HistorySta';
			this.$refs.HistorySta.getPointList(parmas);
			this.term = null;
		},
		switchNavMenu(type) {
			this.page = type;
			if (type == 'HistorySta') {
				this.$refs.HistoryExp.checkedArr = [];
				this.$refs.HistoryExp.checkedDevice = [];
				this.$refs.HistoryExp.checkedPointId = [];
				this.$refs.HistoryExp.isIndeterminate = false;
				this.$refs.HistoryExp.checkAll = false;
				this.$refs.HistorySta.showRelevance = true;
			}
			this.$forceUpdate();
			// // console.log(this.$refs.HistorySta.showRelevance)
		},
		pagechange(type = 'HistoryExp', item) {
			this.page = type;
			if (type === 'HistoryExp') {
				var select = {};
				for(let key in item){
					select[key] = item[key]
				}
				var time = item.fileTime;
				var y = time.substr(0, 4);
				var M = time.substr(5, 2);
				var d = time.substr(8, 2);
				var h = time.substr(11, 2);
				var m = time.substr(14, 2);
				select.fileTime = y + M + d + h + m;
				this.$refs.HistoryExp.relevanceDate(item, select);
			}
		},
		//弹出框按钮点击事件
		layerBtnClick(type) {
			if (type === 'briefReportCon') {
				$('.layerContainerBox').fadeOut(300);
				let form = this.$refs.briefReportLayer.form;
				this.$refs.briefReport.showLayer(form);
			} else if (type === 'preview') {
				$('.layerContainerBox').fadeOut(300, () => {
					this.batchParseFile(this.checkedDevice);
				});
			}
		},
		// 设置报告期数
		setThePeriods() {
			this.dialogFormVisible = true;
		},
		// 保存报告
		saveReport() {
			var data = {
				term: this.term,
				id: this.pointId
			};
			for (let key in this.saveData) {
				data[key] = this.saveData[key];
			}
			this.axios.post('web2/upblas/mmo/prp', data).then(res => {
				if(res.status){
					this.$message({
						showClose: true,
						message: '报告后台生成中，请稍后进入当前页面查看！',
						type: 'success'
					});
					this.dialogFormVisible = false;
					if(this.eventData != null){
						$(this.eventData.target).css({
							color: '#a205bd'
						})
					}
					if(this.$parent.$parent.hasOwnProperty('closeGenerateReport')){
						this.$parent.$parent.closeGenerateReport();
					}
				}
			});
		},
		//解析单个文件 => 预览
		filePreview(item) {
			// // console.log(item, "123123123123");
			this.axios.post(`web2/upblas/mmo/dfile`, { fileId: item.id }).then(res => {
				if (res.status) {
					this.preview = true;
					this.fileBaseData = res.data;
					let fileType = this.fileBaseData.fileName.split('.')[1];
					// this.fileBaseData.deviceparam = this.$refs.fileLayer.item.deviceParamInfo;
					for (let i = 0; i < res.data.xdata.length; i++) {
						res.data.xdata[i] = Number(Number(res.data.xdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.ydata.length; i++) {
						res.data.ydata[i] = Number(Number(res.data.ydata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.zdata.length; i++) {
						res.data.zdata[i] = Number(Number(res.data.zdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.dataTime.length; i++) {
						if (fileType === 'bin') {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						} else {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						}
					}

					let arr = [res.data.xmax, res.data.ymax, res.data.zmax];
					// // console.log(fileType);
					let data = {
						sampleRate: res.data.sampleRate,
						fileType: fileType,
						date: res.data.dataTime,
						series: [res.data.xdata, res.data.ydata, res.data.zdata],
						maxData: arr.max(),
						units: res.data.paramUnit,
						maxXData: res.data.xmax,
						maxYData: res.data.ymax,
						maxZData: res.data.zmax,
						mainFX: res.data.xfrequency,
						mainFY: res.data.yfrequency,
						mainFZ: res.data.zfrequency,
						maxXTime: res.data.xmaxTime,
						maxYTime: res.data.ymaxTime,
						maxZTime: res.data.zmaxTime,
						fileName: res.data.fileName,
						fileTimeStamp: res.data.fileDate,
						pointName: res.data.pointName,
						deviceParam: res.data.deviceParam,
						companyName: res.data.companyName,
						address: res.data.address,
						deviceNum: res.data.deviceNum,
						projectName: res.data.projectName,
						negativeDelay: res.data.negativeDelay,
						// pageType: 1, //是否显示关联按钮
						fileTime: item.fileTime, //跳转关联时所需数据
						pid: item.pid //跳转关联时所需数据
					};
					//是否显示关联按钮
					if(item.hasOwnProperty('pageType')){
						data.pageType = item.pageType;
					}
					this.fileBaseData = data;
					setTimeout(() => {
						this.$refs.previewChart.drawChart(data);
					}, 100);
				} else {
				}
			});
		},
		//按钮组件点击事件回调
		buttonCallback(data, val) {
			this.saveData = {};
			if (val == 'save') {
				this.saveData = data;
				this.setThePeriods();
			} else if (val == 'preview') {
				$('.setup-report').show();
				this.$refs.setupReport.preview(data);
			}
		},
		//显示简报信息弹窗
		showBriefReportLayer() {
			this.$refs.briefReportLayer.showLayer(this.fileBaseData);
			this.$refs.briefReport.setWordImages(this.fileBaseData);
		},
		// 隐藏报告预览父级DOM
		closeDom() {
			$('.setup-report').hide();
		},
		// 还原页面
		reductionPage(){
			this.page = 'HistorySta';
			this.$refs.HistorySta.showRelevance = false;
			this.$refs.HistoryExp.reductionData()
		}
	}
};
</script>

<style lang="scss">
// 设置报告期数
.setupNper {
	width: 260px !important;

	.el-dialog__body {
		padding: 25px 0 !important;
	}

	.setup-input {
		display: flex;
		justify-content: center;
		align-items: center;

		.el-input {
			width: 130px;
			margin: 0 6px;
			
			.el-input__inner{
				text-align: center;
			}
		}
	}
}
</style>
<style scoped lang="scss">
.historyPage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;

	.setup-report {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		display: none;
	}
}
</style>
