// 数据相关页面下载excel
import Vue from "vue";
import XlsxPopulate from 'xlsx-populate';

// table: 表头和表格数据
// time: 事件时刻
// width: 表每列宽度

Vue.prototype.downloadExcel = function(table = [], time = '', width = []) {
	if (table.length == 1) {
		this.$message.error('生成失败！数据为空');
		return;
	}
	// // console.log(table)
	// 文档名称
	var timestamp = (new Date()).format('yyyy-MM-dd hh_mm_ss');
	var letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S",
		"T", "U", "V", "W", "X", "Y", "Z"
	];
	var widthLen = width.length;
	var heard = table[0];
	var heardLen = heard.length;
	var list = table[1];
	// // console.log(list)
	var listLen = list.length;
	XlsxPopulate.fromBlankAsync()
		.then(workbook => {
			// 第一个sheet页
			let wb = workbook.sheet("Sheet1");
			// 设置列宽
			for(let i = 0; i<widthLen;i++){
				wb.column(`${letter[i]}`).width(width[i]);
			}
			
			// 设置行高
			wb.row(1).height(30);
			wb.row(4).height(50);
			wb.row(listLen+6).height(50);
			// 标题合并单元格  A列到L列,1行到2行合并
			wb.range(`A1:${letter[heardLen-1]}1`).merged(true)
				.value("原始数据记录表")
				.style("verticalAlignment", "center") //垂直居中
				.style("horizontalAlignment", "center") //水平位置
				.style("border", true)
				.style("fontSize", 18);
			// 设置第二列
			wb.range(`A2:${letter[heardLen-1]}2`).merged(true)
				.value("表格编号：JC-ZD0001")
				.style("verticalAlignment", "center") //垂直居中
				.style("horizontalAlignment", "right") //水平位置
				.style("border", true);
			// 设置第三列
			wb.range(`A3:${letter[heardLen-1]}3`).merged(true)
				.value(`事件时刻：${time}`)
				.style("verticalAlignment", "center") //垂直居中
				.style("horizontalAlignment", "left") //水平位置
				.style("border", true);
			// 设置第四列
			wb.range(`A4:${letter[heardLen-1]}4`).merged(true)
				.value(`爆破参数：`)
				.style("verticalAlignment", "center") //垂直居中
				.style("horizontalAlignment", "left") //水平位置
				.style("wrapText", true) //自动换行
				.style("border", true);

			for (let i = 0; i < heardLen; i++) {
				// 表头
				wb.cell(`${letter[i]+'5'}`)
					.value(`${heard[i]}`)
					.style("border", true)
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "center"); //水平位置
			}
			for (let i = 0; i < listLen; i++) {
				// 内容
				let n = list[i];
				let index = 5 + n.id;
				let num = 0;
				for (let key in n) {
					if(key != 'id'){
						wb.cell(`${letter[num]+index}`)
							.value(`${n[key]}`)
							.style("border", true)
							.style("verticalAlignment", "center") //垂直居中
							.style("horizontalAlignment", "center"); //水平位置;
							num += 1;
					}
				}
			}
			// 设置备注
			wb.range(`A${listLen+6}:${letter[heardLen-1] + (listLen+6).toString()}`).merged(true)
					.value(`备注：`)
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "left") //水平位置
					.style("wrapText", true) //自动换行
					.style("border", true);
			// 合并最后一行空白区域
			wb.range(`A${listLen+7}:${letter[heardLen-5] + (listLen+7).toString()}`).merged(true)
					.value('')
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "left") //水平位置
					.style("wrapText", true) //自动换行
					.style("border", true);
			// 设置检测
			wb.range(`${letter[heardLen-4] + (listLen+7).toString()}:${letter[heardLen-3] + (listLen+7).toString()}`).merged(true)
					.value('检测：')
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "left") //水平位置
					.style("wrapText", true) //自动换行
					.style("border", true);
			// 设置审核
			wb.range(`${letter[heardLen-2] + (listLen+7).toString()}:${letter[heardLen-1] + (listLen+7).toString()}`).merged(true)
					.value('审核：')
					.style("verticalAlignment", "center") //垂直居中
					.style("horizontalAlignment", "left") //水平位置
					.style("wrapText", true) //自动换行
					.style("border", true);
			// Write to file.
			workbook.outputAsync().then(function(blob) {
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					// If IE, you must uses a different method.
					window.navigator.msSaveOrOpenBlob(blob, timestamp + '.xlsx');
				} else {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					document.body.appendChild(a);
					a.href = url;
					a.download = timestamp + '.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
				}
			})
		});
}
