export default () => {
	return {
		DOM: '',
		tree: [],
		changeSize: 30,
		selectItem: [],
		isIndeterminate: false, //是否出现非全选样式
		checkAll: false, //是否全选
		checkedArr: [],//多选框选中时多选框绑定的数据index
		checkedIndex: [],//多选框选中时存储的数据index
		checkedDevice: [], //多选框选中数据的id
		checkedPointId: [], //多选框选中数据id为零的测点id
		checkedId:{},//多选框选中数据中标红的一条数据id和测点id
		checkAllStatus: 0,
		dataSize: 0,
		dataPoint: 0,
		searchType: {
			id: 1,
			name: '企业'
		},
		number: 0,
		enterprise: [],
		project: [],
		stationLine: [],
		station: [],
		selectDate: {},
		queryFile: 2, // 关联数据选择的查询文件条件
		queryFileList: [
			{
				id: 2,
				name: '全部数据'
			},
			{
				id: 1,
				name: '有数据'
			},
			{
				id: 0,
				name: '无数据'
			}
		],// 关联数据选择的查询文件条件选择列表
		selectStation: 2,
		stationType: [
			{
				id: 2,
				name: '全部测点'
			},
			{
				id: 1,
				name: '启用测点'
			},
			{
				id: 0,
				name: '停用测点'
			}
		], //关联数据查询测点条件选择列表
		placeholder: '企业',
		item: {
			rows: [],
			page: 1,
			size: 30,
			allPage: 0,
			total: 0,
			type: 'historyExp',
			dataType: 'list',
			text: '暂时没有数据哦--',
			rank: 'number', // 排序依据
			// rank: '', // 排序依据
			type_: 0
		},
		form: {
			organization: null,
			type: 0,
			stationLine: null,
			timeS: null,
			timeE: null,
		},
		beforeName: '',
		beforeType: '',
		showRelevance: false
	}
}
