const reportManagement = resolve => require(["../views/reportManagement/vibrationReport/index.vue"], resolve); //振动报告
const deformationReport = resolve => require(["../views/reportManagement/deformationReport/index.vue"], resolve); //变形报告
export default [{
		path: "/reportManagement",
		name: "reportManagement",
		component: reportManagement,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 振动报告管理
	{
		path: "/deformationReport",
		name: "deformationReport",
		component: deformationReport,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 变形报告管理
]
