import Vue from "vue";
import router from "../../router/index.js";
// bus
Vue.prototype.bus = new Vue();

/**
 * 时间格式化
 * @param format 时间格式
 * @returns {*}
 */
Date.prototype.format = function(format) {
	let date = new Date(this);
	format = format ? format : 'yyyy-MM-dd hh:mm:ss';
	const o = {
		"M+": date.getMonth() + 1, // month
		"d+": date.getDate(), // day
		"h+": date.getHours(), // hour
		"m+": date.getMinutes(), // minute
		"s+": date.getSeconds(), // second
		"q+": Math.floor((this.getMonth() + 3) / 3), // quarter
		"S": date.getMilliseconds() // millisecond
	};
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (const k in o) {
		if (new RegExp("(" + k + ")").test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k])
				.length));
		}
	}
	return format;
};

// 路由跳转
Vue.prototype.toRouter = (name, data = {}, way = '') => {
	if (way == "") {
		router.push({
			name: name,
		})
	} else if (way == 'params') {
		router.push({
			name: name,
			params: data,
		})
	} else if (way == 'query') {
		router.push({
			name: name,
			query: data,
		})
	}
};

// 时间先后判定
Vue.prototype.timeComparison = function(start, end) {
	if (moment(end).isAfter(start)) {
		return true;
	} else {
		Dialog.alert({
			message: "结束时间不能小于起始时间"
		});
		return false;
	}
};

// 数字每三位增加一个逗号
Vue.prototype.toThousands = function(num) {
	// // console.log(num)
	let narr = num.toString().split('.');
	var txt = '';
	txt = (narr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	if(narr.length == 2){
		txt = txt + '.' + narr[1];
	}
	return txt;  
}

/**
 * 计算每页序号
 * @param index
 * @param item
 * @returns {*}
 */
Vue.prototype.getListNumber = function(index, item) {
	return (item.page - 1) * item.size + (index + 1);
};

/**
 * 计算删除之后还有几页
 * @param total 数据总条数
 * @param page 当前页数
 * @param size 每页条数
 * @param length 删除的条数
 * @returns {number|*}
 */
Vue.prototype.calculatePages = function(total, page, size, length = 1) {
	let allPage = Math.ceil(total / size);
	let total_ = total - length;
	let page_ = Math.ceil(total_ / size);

	if (allPage === page) {
		if (page_ === page) {
			return page;
		} else {
			return page - 1 ? page - 1 : 1;
		}
	} else {
		return page;
	}
};

/**
 * 保留小数位数
 * @param value
 * @param length
 * @returns {number}
 */
Vue.prototype.toFixed = function(value, length) {
	return Number(Number(value).toFixed(length))
}

/**
 * 返回数组中的最大值
 * @param boolean 为true计算绝对值
 * @returns {number}
 */
Array.prototype.max = function(boolean = false) {
	let absArray;
	if (boolean)
		absArray = this.map(item => Math.abs(item))
	else
		absArray = this;
	return Math.max(...absArray)
}

/**
 * 返回数组中的最小值
 * @param boolean
 * @returns {number}
 */
Array.prototype.min = function(boolean = false) {
	let absArray;
	if (boolean)
		absArray = this.map(item => Math.abs(item))
	else
		absArray = this;
	return Math.min(...absArray)
}

/**
 * 去除数组中重复的值
 * @returns {any[]}
 */
Array.prototype.repeat = function() {
	return Array.from(new Set(this))
}

// 自定义获取url参数
Vue.prototype.getUrlText = (variable) => {
	// // console.log(window.location)
	var ind = window.location.hash.indexOf('?');
	var query = window.location.hash.substring(ind + 1);
	// // console.log(query)
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return (false);
};

// 自定义数据嵌对象混合数据深拷贝
Vue.prototype.deepCopy = function(obj) {
	let type = Object.prototype.toString.call(obj)
	if (type == "[object Array]") {
		let backObj = [];
		for (let val of obj) {
			backObj.push(this.deepCopy(val))
		};
		return backObj;
	}
	if (type == "[object Object]") {
		let backObj = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				backObj[key] = this.deepCopy(obj[key])
			}
		};
		return backObj;
	}
	return obj;
};

// 自定义权限判断函数
Vue.prototype.judgment = function(data, val = null, obj) {
	// // console.log(obj)
	// data 要判断的权限数组名 | val 要判断的权限id | obj 当前权限数组所在页面的this
	if (obj[data].includes(val)) {
		return true
	} else {
		return false
	}
}

// 小数乘法
Vue.prototype.rideNum = function(num1, num2) {
	var num1s, num2s;
	var m = 0;
	var num1Acc = num1.toString().replace(".", ""),
		num2Acc = num2.toString().replace(".", "");
	try {
		num1s = num1.toString().split(".");
		m += num1s.length = 2 ? num1s[1].length : 0;
	} catch (e) {}
	try {
		num2s = num2.toString().split(".");
		m += num2s.length = 2 ? num2s[1].length : 0;
	} catch (e) {}
	return Number(num1Acc) * Number(num2Acc) / Math.pow(10, m);
}

// 小数除法
Vue.prototype.exceptNum = function(arg1, arg2) {
	var t1 = 0,
		t2 = 0,
		r1, r2;
	try {
		t1 = arg1.toString().split(".")[1].length
	} catch (e) {}

	try {
		t2 = arg2.toString().split(".")[1].length
	} catch (e) {}
	r1 = Number(arg1.toString().replace(".", ""))

	r2 = Number(arg2.toString().replace(".", ""))

	return (r1 / r2) * Math.pow(10, t2 - t1);
}


// 文件名拆解成时间
Vue.prototype.dismantling = function(fileTime = '') {
	// // console.log(fileTime)
	if (fileTime == null || fileTime == undefined || (typeof fileTime) == 'object' || (typeof fileTime) ==
		'function') {
		return ''
	}
	if ((typeof fileTime) != 'string') {
		fileTime = fileTime.toString();
	}
	var txt = '';
	var Y = fileTime.substr(0, 4);
	var M = fileTime.substr(4, 2);
	var D = fileTime.substr(6, 2);
	var h = fileTime.substr(8, 2);
	var m = fileTime.substr(10, 2);
	var s = fileTime.substr(12, 2);
	if (Y) {
		txt = txt + Y;
	}
	if (M) {
		txt = txt + "-" + M;
	}
	if (D) {
		txt = txt + "-" + D;
	}
	if (h) {
		txt = txt + " " + h;
	}
	if (m) {
		txt = txt + ":" + m;
	}
	if (s) {
		txt = txt + ':' + s;
	}
	return txt
}

/**
 * 消息提示框
 * @param text 显示的文字内容
 * @param type 提示类型 default、success、warning、error
 * @param time 提示框显示时间
 */
Vue.prototype.$messages = function(text, type, time) {
	return new messages(text, type, time)
};
const messages = function(text, type, time) {
	this.text = text;
	this.type = type === undefined ? 'default' : type;
	this.time = time === undefined ? 2000 : time;
	if (type === undefined) {
		this.icon = 'el-icon-info'
	} else if (type === 'success') {
		this.icon = 'el-icon-success'
	} else if (type === 'warning') {
		this.icon = 'el-icon-warning'
	} else if (type === 'error') {
		this.icon = 'el-icon-error'
	}
	this.init();
};
messages.prototype = {
	constructor: messages,
	init: function() {
		var timestamp = new Date().getTime();
		var html = '<div class="messages ' + timestamp + ' ' + this.type + '">' +
			'    <i class="icon ' + this.icon + '"></i>' +
			'    <span class="text">' + this.text + '</span>' +
			'</div>';
		$('body').append(html);
		this.messageBox = $('.messages.' + timestamp);
		this.show();
	},
	show: function() {
		const _this = this;
		setTimeout(function() {
			_this.messageBox.addClass('show');
			setTimeout(function() {
				_this.hide();
			}, _this.time)
		}, 200)
	},
	hide: function() {
		const _this = this;
		this.messageBox.removeClass('show');
		setTimeout(function() {
			_this.messageBox.remove();
		}, 500)
	}
};

/**
 * element加载动画
 * element loading
 * @type {{hide(): void, show(): void}}
 */
Vue.prototype.loadingAnimation = {
	load: null,
	show(text, icon, bgColor) {
		// // console.log(icon)
		this.hide();
		this.load = Vue.prototype.$loading({
			target: '.animationContainer',
			lock: true,
			text: text ? text : '',
			spinner: icon ? icon : '',
			fullscreen: true,
			background: bgColor ? bgColor : 'rgba(0,0,0,0.05)'
		})
	},
	hide() {
		if (this.load) {
			this.load.close();
			this.load = null;
		}
	}
};

/**
 * 时间字符串处理
 * @param time 待处理的时间字符串
 * @param str 时间字符串处理后需要拼接的字符串
 */

Vue.prototype.timeHandle = function(time, str = null) {
	if (time == undefined || time == null || time == '') {
		return '-'
	}

	if ((typeof time) != 'string') {
		time = time.toString();
	}

	var Y = time.substring(0, 4);
	var M = time.substring(4, 6);
	var D = time.substring(6, 8);
	var h = time.substring(8, 10);
	var m = time.substring(10, 12);
	var s = time.substring(12, 14);
	var string = '';
	if (str != null) {
		string = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s + str;
	} else {
		string = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
	}
	return string;

};
/**
 * 计算每页序号
 * @param index
 * @param item
 * @returns {*}
 */
Vue.prototype.getListNumber = function(index, item) {
	return (item.page - 1) * item.size + (index + 1);
};

/**
 * 小数补全函数
 * @param value 数据
 * @param len 需要补全的小数位数
 */
Vue.prototype.returnFloat = (value, length) => {
	var val = '';
	var num = null;
	var xsd = value.toString().split(".");
	if (xsd.length > 1) {
		var xlen = xsd[1].length;
		if (xlen < length) {
			var len = length - xlen;
			for (let i = 0; i < len; i++) {
				val = val + '0';
			}
			val = xsd[1] + val;
		} else if (xlen == length) {
			val = xsd[1];
		} else {
			val = xsd[1].slice(0, length);
		}
	} else {
		if (xsd[0] != 0) {
			for (let i = 0; i < length; i++) {
				val = val + '0';
			}
		}
	}
	if (xsd.length != 1) {
		num = xsd[0].toString() + '.' + val;
	} else {
		if (xsd[0] != 0) {
			num = xsd[0].toString() + '.' + val;
		} else {
			num = xsd[0].toString();
		}
	}

	return num;
}

/**
 * 百度地图经纬度转为高德地图经纬度
 * @param lng
 * @param lat
 * @returns {{Q: number, R: number, lng: number, lat: number}}
 * @constructor
 */
Vue.prototype.BMapToAMap = function(lng, lat) {
	let PI = 3.14159265358979324;
	let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
	let x = lng - 0.0065,
		y = lat - 0.006;
	let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
	let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
	let gcjLon = z * Math.cos(theta);
	let gcjLat = z * Math.sin(theta);
	return {
		'lng': gcjLon,
		'lat': gcjLat,
		'R': gcjLon,
		'Q': gcjLat
	};
};

/**
 * 高德地图经纬度转为百度地图经纬度
 * @param lng
 * @param lat
 * @returns {{Q: number, R: number, lng: number, lat: number}}
 * @constructor
 */
Vue.prototype.AMapToBMap = function(lng, lat) {
	var X_PI = Math.PI * 3000.0 / 180.0;
	var x = lng,
		y = lat;
	var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
	var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
	var bd_lng = z * Math.cos(theta) + 0.0065;
	var bd_lat = z * Math.sin(theta) + 0.006;

	return {
		'lng': bd_lng,
		'lat': bd_lat,
		'R': bd_lng,
		'Q': bd_lat
	};
};


/**
 * 萨道夫斯基回归公式
 * @type {{calculateV(*=, *, *, *=): *, calculateQ(*, *=, *, *): *, calculate(*): {csa: number, csk: number}, calculateR(*, *=, *, *): *}}
 */
Vue.prototype.Sadowski = {
	//速度 V
	calculateV(Q, R, K, A) {
		return K * Math.pow(Math.pow(Q, 1 / 3) / R, A);
	},
	//药量 Q
	calculateQ(V, R, K, A) {
		return Math.pow(R, 3) * Math.pow(V / K, 3 / A);
	},
	//距离 R
	calculateR(V, Q, K, A) {
		return Math.pow(Q, 1 / 3) * Math.pow(K / V, 1 / A);
	},
	//计算k&a
	calculate(data) {
		let c1 = 0,
			c2 = 0,
			c3 = 0,
			c4 = 0,
			c5 = 0;
		let x = {},
			y = {},
			V = data.v,
			Q = data.q,
			R = data.r,
			length = data.v.length;

		for (let i = 0; i < length; i++) {
			x[i] = Math.log(Math.pow(Q[i], 1 / 3) / R[i]);
			y[i] = Math.log(V[i]);
			c1 = c1 + x[i] * x[i];
			c2 = c2 + x[i];
			c3 = c3 + x[i] * y[i];
			c5 = c5 + y[i];
		}
		c4 = length;
		let csk = (c3 / c1 - c5 / c2) / (c2 / c1 - c4 / c2);
		let csa = (c3 - c2 * csk) / c1;
		return {
			csb: csk,
			csa,
			csk: Math.exp(csk)
		};
	}
}

/**
 * 保留小数位数
 * @param value
 * @param length
 * @returns {number}
 */
Vue.prototype.toFixed = function(value, length) {
	return Number(Number(value).toFixed(length))
}
// 防抖函数
Vue.prototype.antiShake = function(func,delay){//函数的防抖，该函数的返回值是一个函数
  let timer = null;
  return function (...args){
    if(timer) clearTimeout(timer); //清上一次的计数器

    timer = setTimeout(() =>{
      func.apply(this,args); //调用func ，同时改变它的this 为 window
    },delay)
  }//return
}

