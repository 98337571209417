export default () => {
	return {
		isIndeterminate: false, //是否出现非全选样式
		checkAll: false, //是否全选
		changeSize: 30,
		checkedDevice: [],//多选框选中数据的id
		checkedPoint: '',//多选框选中数据的pid
		dataSize: 0,
		dataPoint: 0,
		placeholder: '企业',
		enterprise: [],
		project: [],
		stationLine: [],
		station: [],
		deviceType: [],
		batchParse: [],
		selectRelevanceData: {},
		item: {
			rows: [],
			page: 1,
			size: 30,
			allPage: 0,
			total: 0,
			time: false,
			type: "historySta",
			dataType: "list",
			text: "暂时没有数据哦--",
		},
		form: {
			organization: null,
			type: null,
			pid: null,
			timeS: null,
			timeE: null,
		},
		pointList: [],// 测点选择列表
		pointId:'',// 传递过来的id
		pointInfo:{},//传递过来的测点信息
		relationId: null,// 点击关联的数据id
		showRelevance:false,// 关联测点页面是否显示
		parmas: {},//参数	
	}
}
