import Vue from "vue";
import VueRouter from "vue-router";

// 主页
const index = resolve => require(["../views/index/index.vue"], resolve);
// 提示页面
const hint = resolve => require(["../views/404.vue"], resolve);
// 登录
const login = resolve => require(["../views/login.vue"], resolve);

// 数据 
import dataManagement from "./dataManagement.js";
// 报告
import reportManagement from "./reportManagement.js";
// 测点
import measuringPoint from "./measuringPoint.js";
// 报警
import alarm from "./alarm.js";
// 事件
import event from "./event.js";
// 总结
import summary from "./summary.js";


// 工具
import tool from "./tool.js";
// 角色管理、用户管理、设备管理
import system from "./system.js";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [{
		path: "/",
		name: "login",
		component: login,
		meta: {
			headerShow: false, //顶部状态栏是否显示
			menuShow: false, //顶部菜单是否显示
		}
	}, // 登录
	{
		path: "/index",
		name: "index",
		component: index,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: false, //顶部菜单是否显示
		}
	}, // 主页
	{
		path: "/404",
		name: "hint",
		component: hint,
		meta: {
			headerShow: false, //顶部状态栏是否显示
			menuShow: false, //顶部菜单是否显示
		}
	}, // 提示页面
	...dataManagement,
	...reportManagement,
	...measuringPoint,
	...alarm,
	...event,
	...summary,

	...tool,
	...system
];

const router = new VueRouter({
	// mode: 'history',
	// base: '/dist/',
	routes
});

export default router;
