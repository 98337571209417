// 预览数据生成Word的时候提示“Uncaught TypeError: Cannot set property namespaceURI of #<Element> which has only a getter”请查看README.md

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// vueScroll
import vuescroll from 'vuescroll';
Vue.use(vuescroll);
Vue.prototype.$vuescrollConfig = {
	bar: {
		background: "rgba(144,147,153,0.5)"
	}
};

// 引入公共样式
import "./assets/common.css";

// 引入自定义常量
import "./assets/config.js"

// 引入全局组件
import components from './components/index.js'
Vue.use(components);

// 本地生成Excel(总结页面除外)
import "./assets/downloadExcel.js";
// 总结页面excel下载
import "./assets/summaryDownloadExcel.js";
// 项目业务表格下载（普通表格下载）
import './assets/tableDownloadExcel'

// html2canvas
import html2canvas from 'html2canvas';
Vue.prototype.html2canvas = html2canvas;

// 生成pdf
import htmlpdf from "./assets/generatepdf.js";
Vue.use(htmlpdf);
// 生成img
import htmlimg from "./assets/generateimg.js";
Vue.use(htmlimg);

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 设置消息提示时长
import {
	Message
} from 'element-ui'
Vue.prototype.$message = function(msg) {
	// // console.log(msg)
	//根据msg对象中的type类型判断消息提示的类型
	let msgObj = {
		showClose: true,
	};

	if (typeof msg == "object") {
		for (let key in msg) {
			if (key != 'type') {
				msgObj[key] = msg[key];
			}
		}
	} else {
		msgObj.message = msg;
	}

	msgObj.duration = 2000;
	let msgType = msg.type || "";
	switch (msgType) {
		case 'success':
			return Message.success(msgObj);
			break;
		case 'warning':
			return Message.warning(msgObj);
			break;

		case 'error':
			return Message.error(msgObj);
			break;

		default:
			return Message(msgObj);
	}
}
//jq
import $ from 'jquery';
window.$ = $;

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.echarts = echarts;

// axios
import Axios from "./assets/axios.js";
Vue.prototype.axios = Axios;

// 自定义函数
import "./assets/common/until.js";

// 检测是否有localStorage变量
if (!localStorage.getItem("lInfo")) {
	localStorage.setItem('lInfo', "");
	localStorage.setItem("userName", '');
	localStorage.setItem('data', '');
}


Vue.config.productionTip = false;


// 全局过滤器(无数据时显示横线)
Vue.filter('dataDetect', (val) => {
	if (val === '' || val == null || val == undefined) {
		return '-';
	} else {
		return val;
	}
})

var menuList = [];
var menuLen = 0;

if (localStorage.getItem('data')) {
	var data = JSON.parse(localStorage.getItem('data'));
	if (data.hasOwnProperty('myAuthorities')) {
		menuList = data.myAuthorities;
		menuLen = menuList.length;
	}
}

// 路由监控
router.beforeEach((to, from, next) => {
	// // console.log(to);
	// // console.log(from)
	var id = null;
	var childrenId = null;
	var jump = false;
	var pageUrlOne = Vue.prototype.constData.pageUrlOne;
	var pageUrlTwo = Vue.prototype.constData.pageUrlTwo;
	var toolRouter = Vue.prototype.constData.toolRouter;
	// 清除页面记录的接口遇到401重新请求的次数
	sessionStorage.clear();

	if (to.name != 'index' && to.name != 'login' && to.name != 'hint') {
		if (to.meta.menuType == 1) {
			var lenOne = pageUrlOne.length;
			for (let i = 0; i < lenOne; i++) {
				var val = pageUrlOne[i];
				if (val.hasOwnProperty("deformationPath")) {
					if (to.name == val.path) {
						id = val.id;
						break
					}
				} else {
					if (to.name == val.path) {
						id = val.id;
						break
					}
				}

			}
		} else if (to.meta.menuType == 2) {
			var lenTwo = pageUrlTwo.length;
			for (let i = 0; i < lenTwo; i++) {
				var val = pageUrlTwo[i];
				if (to.path.indexOf('/tool/') != -1) {
					if (val.path == 'tool') {
						id = val.id;
					}
					let tlen = toolRouter.length;
					// 循环定位
					children:
						for (let t = 0; t < tlen; t++) {
							let mLen = toolRouter[t].menu.length;
							for (let m = 0; m < mLen; m++) {
								var mData = toolRouter[t].menu[m];
								if (to.name == mData.pathName) {
									childrenId = mData.id;
									break children;
								}
							}
						}
				} else {
					if (to.name == val.path) {
						id = val.id;
						break
					}
				}
			}
		}
		// 循环标志
		routerJump:
			for (let n = 0; n < menuLen; n++) {
				let item = menuList[n]
				if (item.id == id) {
					if (to.path.indexOf('/tool/') != -1) {
						var cLen = item.children.length;
						for (let c = 0; c < cLen; c++) {
							if (item.children[c].id == childrenId) {
								jump = true;
								break routerJump;
							}
						}
					} else {
						jump = true;
						break
					}
				} else {
					jump = false;
				}
			}

		if (jump) {
			store.state.headerShow = to.meta.headerShow;
			store.state.menuShow = to.meta.menuShow;
			if (to.meta.menuShow) {
				store.state.menuType = to.meta.menuType;
			}
			if (to.meta.hasOwnProperty('childBackShow')) {
				store.state.childBackShow = to.meta.childBackShow;
			} else {
				store.state.childBackShow = '';
			}
			next();
		} else {
			if (from.name != null && from.name != to.name) {
				store.state.headerShow = from.meta.headerShow;
				store.state.menuShow = from.meta.menuShow;
				if (from.meta.menuShow) {
					store.state.menuType = from.meta.menuType;
				}
				if (from.meta.hasOwnProperty('childBackShow')) {
					store.state.childBackShow = from.meta.childBackShow;
				} else {
					store.state.childBackShow = '';
				}
				next(false);
			} else {
				// next('/')
			}
		}

	} else {
		store.state.headerShow = to.meta.headerShow;
		store.state.menuShow = to.meta.menuShow;
		if (to.meta.menuShow) {
			store.state.menuType = to.meta.menuType;
		}
		if (to.meta.hasOwnProperty('childBackShow')) {
			store.state.childBackShow = to.meta.childBackShow;
		} else {
			store.state.childBackShow = '';
		}
		next();
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
