<!--上传图片-->
<template>
  <ul class="uploadImages imgBox">
    <li @click="selectImg" class="selectImg el-icon-plus"><input type="file" class="select" multiple accept="image/*" title=""></li>
  </ul>
</template>

<script>
  export default {
    name: "uploadImage",
    props: {
      uploadImagesCallback:{
        type: Function,
        required: false
      },
      uploadImagesConfig: Object
    },
    created() {
      const config = this.uploadImagesConfig;
      this.const.uploadImagesArr[config.name] = [];
      this.init(config, this.const.uploadImagesArr[config.name]);
    },
    methods: {
      init: function (config, arr) {
        this.count = config.imgCount === undefined ? 8 : config.imgCount; //上传图片最大数量
        this.compress = config.compress === undefined ? false : config.compress; //是否压缩图片
        this.scale = config.scale === undefined ? 1 : config.scale; //原图缩小的倍数 默认1不缩小
        this.quality = config.quality === undefined ? 1 : config.quality; //图片质量 0~1 默认未1不缩小
        this.showBig = config.showBig === undefined; //是否查看大图
        this.imgArr = arr;
        this.status = true;
        this.len = 0;
      },
      selectImg: function (e) {
        var _this = this;
        this.li = $(e.target);
        this.parent = this.li.parents('.uploadImages');
        this.input = $(e.currentTarget).find('input');
        if (this.imgArr.length === 0) {
          this.len = 0;
        }
        this.input.unbind().change(function (e) {
          //获取目标文件
          var files = e.target.files || e.dataTransfer.files;
          //如果目标文件存在
          if (files) {
            _this.repetition(files);
            if (_this.repetitionT) {
              var fileReader = new FileReader();
              if (_this.compress) {
                //压缩图片
                _this.CompressImages(fileReader, files, 0);
              } else {
                //不压缩图片
                _this.noCompressImages(fileReader, files, 0);
              }
            }
          }
        })
      },
      CompressImages: function (fileReader, files, index) {
        var _this = this;
        if (files[index]) {
          fileReader.readAsDataURL(files[index]);
          fileReader.onload = function () {
            var oImg = new Image();
            oImg.src = this.result;
            oImg.onload = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              canvas.width = oImg.width / _this.scale;
              canvas.height = oImg.height / _this.scale;
              ctx.drawImage(oImg, 0, 0, canvas.width, canvas.height);
              var base64 = canvas.toDataURL('image/jpeg', _this.quality);
              _this.imgArr.push({
                name: files[index].name,
                base64str: base64
              });
              _this.showImg(files[index].name, base64);
              _this.CompressImages(fileReader, files, ++index);
            };
          };
        } else {
          this.callback(this.imgArr);
        }
      },
      noCompressImages: function (fileReader, files, index) {
        var _this = this;
        if (files[index]) {
          this.imgArr.push(files[index]);
          fileReader.readAsDataURL(files[index]);
          fileReader.onload = function () {
            _this.showImg(files[index].name, this.result);
            _this.noCompressImages(fileReader, files, ++index)
          };
        } else {
          this.callback(this.imgArr);
        }
      },
      repetition: function (file) {
        var len = true, name = true;
        if (this.len + file.length > this.count) {
          this.$messages('最多只能添加' + this.count + '个文件', 'error');
          len = false;
          this.repetitionT = false;
          // return false
        }
        //验证上传文件是否重复
        if (this.imgArr.length > 0) {
          for (var f = 0; f < this.imgArr.length; f++) {
            for (var i = 0; i < file.length; i++) {
              if (this.imgArr[f].name == file[i].name) {
                this.$messages('有文件已重复，请从新选择！', 'error');
                name = false;
                this.repetitionT = false;
              }
            }
          }
        }
        if (len === true && name === true) {
          this.len += file.length;
          this.repetitionT = true;
          if (this.len == this.count) {
            this.li.parent().hide();
          }
        }
      },
      showImg: function (name, src) {
        // 显示图片
        this.parent.append('<li class=" '+ (this.showBig ? "imgFile zoomImg" : "imgFile") +'"><em class="el-icon-close" data-name="' + name + '"></em><img src="' + src + '"/><p title="'+ name +'">' + name + '</p></li>');
        this.remove = this.parent.find('.imgFile em');
        this.showBigLi = this.parent.find('.imgFile.zoomImg');
        this.showBigP = this.parent.find('.imgFile.zoomImg p');
        this.remove.unbind().on('click', $.proxy(this.removeImg, this));
        this.showBigLi.unbind().on('click', $.proxy(this.showBigImages, this));
        this.showBigP.unbind().on('click', $.proxy(this.showBigImagesP, this));
      },
      removeImg: function (e) {
        e.stopPropagation();
        var em = $(e.currentTarget);
        em.parent().remove();
        var name = em.attr('data-name');
        for (var f = 0; f < this.imgArr.length; f++) {
          if (this.imgArr[f].name == name) {
            this.imgArr.splice(f, 1);
            this.len--;
            if (this.len < this.count) {
              this.li.parent().show()
            }
          }
        }
        this.callback(this.imgArr, this.index)
      },
      showBigImages:function(e){
        new this.showZoomImage().showImg($(e.target).find('img'))
      },
      showBigImagesP:function(e){
        e.stopPropagation();
        new this.showZoomImage().showImg($(e.target).prev('img'))
      },
      callback: function (arr) {
        this.uploadImagesCallback({name: this.uploadImagesConfig.name, data: arr});
      }
    },
  }
</script>

<style>
  ul.imgBox.uploadImages {
    padding: 0 !important;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }

  ul.imgBox.uploadImages li {
    float: right;
    width: 162.5px;
    height: 90px;
    margin-right: 10px;
    background-color: #eee;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  ul.imgBox.uploadImages li img {
    max-height: 100%;
    max-width: 100%;
  }

  ul.imgBox.uploadImages li.selectImg {
    cursor: pointer;
    position: relative;
    font-size: 30px;
    color: #666;
    order: 1;
  }

  ul.imgBox.uploadImages li.selectImg input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  ul.imgBox.uploadImages li.imgFile {
    order: 0;
  }
  ul.imgBox.uploadImages li.imgFile.zoomImg {
    cursor: zoom-in;
  }
  ul.imgBox.uploadImages li.imgFile em {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 11;
    font-size: 16px;
    color: #fff;
  }

  ul.imgBox.uploadImages li.imgFile em:hover {
    background-color: rgba(0, 0, 0, .2);
    cursor: pointer;
  }

  ul.imgBox.uploadImages li.imgFile p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    display: none;
    z-index: 11;
  }

  ul.imgBox.uploadImages li.imgFile:hover em {
    display: flex;
  }

  ul.imgBox.uploadImages li.imgFile:hover em img {
    cursor: pointer;
  }

  ul.imgBox.uploadImages li.imgFile:hover p {
    display: block;
  }

  ul.imgBox.uploadImages li.imgFile:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    z-index: 10;
  }
</style>
