<!--表头-->
<template>
  <div class="tableHeader no-select-text">
    <span :class="t.click ? (t.checked ? 'click select pointer' : 'click pointer') : ''" @click="select($event, t)" v-for="t in tableHeader" :style="{width:t.width}">{{t.name}} <i v-show="t.icon" :class="rank ? 'el-icon-bottom' : 'el-icon-top'"></i></span>
  </div>
</template>

<script>
  export default {
    name: "tableHeader",
    props: {
      tableHeader: {
        type: Array
      },
      showIcon: {
        type: Boolean,
        default: false
      },
      selectHeader: {
        type: Function,
        required: false
      }
    },
    data() {
      return {
        rank: true, //true 为降序，false 为升序
      }
    },
    methods: {
      select(e, item) {
        let self = $(e.currentTarget);
        if (self.hasClass('click')) {
          if (item.checked) {
            this.rank = !this.rank
          } else {
            for (let header of this.tableHeader) {
              header.checked = false;
            }
            item.checked = true;
            this.rank = true
          }
          if (this.selectHeader && typeof this.selectHeader === "function") {
            this.selectHeader(this.rank, item)
          }
        }
      }
    }
  }
</script>

<style scoped>
  .tableHeader {
    line-height: 45px;
    height: 45px;
    flex: 0 0 45px;
    overflow: hidden;
    background-color: #f2f2f2;
  }

  .tableHeader span {
    float: left;
    text-align: left;
    text-indent: 10px;
    color: #999999;
  }

  .tableHeader span.select {
    color: #3b9eff;
  }

  .tableHeader span:first-child,
  .tableHeader span:last-child {
    text-indent: 0;
    text-align: center;
  }
</style>
