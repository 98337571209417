<!--没有数据时显示的模块-->
<template>
  <div class="noData no-select-text">
    <div class="border">
      <img src="../assets/images/noData.png" alt="icon">
    </div>
  </div>
</template>

<script>
  export default {
    name: "noData",
    props: ['tipText'],
    data() {
      return {
        text: ''
      }
    },
    mounted() {
      if (this.tipText) {
        this.text = this.tipText;
      } else {
        this.text = '暂无数据'
      }
    }
  }
</script>

<style scoped>
  .noData {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
    min-height: 240px;
  }

  .noData img {
    width: 240px;
  }

  .noData p {
    text-align: center;
    margin-top: 20px;
  }
</style>
