<template>
	<div class="navMenu-box">
		<el-page-header :class="menuType == 2 ? 'backstage' : ''" v-show="menuShow && childBackShow == ''" title="返回主页" @back="goBack">
			<span slot="content">
				<span v-show="projectName != ''">{{ projectName + '（' + (routerType == 'vibration' ? '振动详情' : '变形详情') + '）' }}</span>
			</span>
		</el-page-header>
		<el-page-header :class="childBackShow != '' ? 'backstage' : ''" v-show="menuShow && childBackShow != ''" :title="'返回' + childBackShow" @back="fallback"></el-page-header>
		<div class="navMenu" v-show="menuShow">
			<div class="menu-box" v-show="menuType == 1">
				<div class="menu-cell" v-show="routerType == 'deformation'">
					<el-menu
						:default-active="activeIndex"
						background-color="#545c64"
						text-color="#fff"
						active-text-color="#ffd04b"
						class="el-menu-demo"
						mode="horizontal"
						@select="handleSelect"
					>
						<el-menu-item v-for="(item, index) in deformation" :index="item.id.toString()" :key="index">{{ item.name }}</el-menu-item>
					</el-menu>
				</div>
				<div class="menu-cell" v-show="routerType == 'vibration'">
					<el-menu
						:default-active="activeIndex"
						background-color="#545c64"
						text-color="#fff"
						active-text-color="#ffd04b"
						class="el-menu-demo"
						mode="horizontal"
						@select="handleSelect"
					>
						<el-menu-item v-for="(item, index) in vibration" :index="item.id.toString()" :key="index">{{ item.name }}</el-menu-item>
					</el-menu>
				</div>
			</div>

			<div class="menu-box" v-show="menuType == 2">
				<el-menu
					:default-active="activeIndexs"
					background-color="#545c64"
					text-color="#fff"
					active-text-color="#ffd04b"
					class="el-menu-demo"
					mode="horizontal"
					@select="handleSelects"
				>
					<el-menu-item v-for="(item, index) in menuTwo" :index="item.id.toString()" :key="index">{{ item.name }}</el-menu-item>
				</el-menu>
			</div>
		</div>
		<div class="search-box" v-show="!menuShow">
			<div class="user-box pointer" @click="goBackground">
				<i v-show="menuTwo.length != 0" class="iconfont icon-UI_icon_houtaiguanli iconStyle "></i>
				<span class="txt">后台管理</span>
			</div>
			<!-- <span class="name">交博爆破测振监管平台</span> -->
		</div>
		<!-- 推送、事件 -->
		<div class="push-report">
			<!-- 推送 -->
			<el-popover placement="bottom" width="400" trigger="click" popper-class="push-list-popover">
				<div class="scrollBox push-box">
					<div class="top-but-box">
						<span class="title-left">推送消息</span>
						<el-popconfirm popper-class="push-popconfirm" title="确定清空推送列表吗？" @confirm="clearPush">
							<el-button slot="reference" size="small">一键清除</el-button>
						</el-popconfirm>
					</div>
					<div class="scroll-box">
						<vue-scroll>
							<div class="scroll-list">
								<div class="card-box pointer" v-for="(i, ind) in pushList" :key="ind" @click="openPush(i)">
									<p class="card-name">
										<!-- 关联数据 -->
										<i v-show="i.mtp == 1" class="icon iconfont icon-guanlian green-color"></i>
										<!-- 报警 -->
										<i v-show="i.mtp == 2" class="icon iconfont icon-baojing red-color"></i>
									</p>
									<div class="content-box">
										<p class="card-content" :title="i.content">{{ i.content }}</p>
									</div>
								</div>
							</div>
						</vue-scroll>
					</div>
				</div>
				<div class="badge focus_on" slot="reference">
					<el-badge :value="pushNum" :max="99" class="item">
						<div class="badge-i-but pointer">
							<span title="推送" class="icon iconfont icon-shoucang"></span>
							<span>推送</span>
						</div>
					</el-badge>
				</div>
			</el-popover>
			<!-- 事件 -->
			<el-badge v-show="reportShow" :value="reportNum > 0 ? 'new' : ''" :hidden="reportNum == 0" class="report-icon">
				<div class="badge-i-but pointer" @click="openEvent">
					<span title="事件" class="icon iconfont icon-kehushangbao"></span>
					<span>事件</span>
				</div>
			</el-badge>
			<el-dialog :visible.sync="visible" custom-class="reportListDialog" :modal-append-to-body="false" @close="state = null">
				<template slot="title">
					<div class="dialog-title"><span class="title">事件列表</span></div>
				</template>
				<div class="report-list-box">
					<div class="screening">
						<el-radio-group v-model="state" @change="refreshData">
							<el-radio-button :label="1">完成</el-radio-button>
							<el-radio-button :label="0">未完成</el-radio-button>
						</el-radio-group>
					</div>
					<div class="list-scroll-box">
						<div class="list-box">
							<ul class="head-ul">
								<li class="width-s">序号</li>
								<li class="time-l">爆破时间</li>
								<li class="width-xl align-left text-overflow">项目名称</li>
								<li class="width-xl align-left text-overflow">爆破单位</li>
								<li class="width-xl align-left text-overflow">监测单位</li>
								<li class="width-icon">事件</li>
								<li class="but-width">报告</li>
								<li class="time">事件时间</li>
							</ul>
							<div class="scroll-box">
								<vue-scroll v-show="item.total">
									<ul v-for="(i, index) in item.rows" :key="index" :class="setLineStyle(i, index)">
										<li class="width-s">{{ index + 1 }}</li>
										<li class="time-l blue-color">{{ i.dtime }}</li>
										<li class="width-xl align-left text-overflow">{{ i.pjname }}</li>
										<li class="width-xl align-left text-overflow">{{ i.bcname }}</li>
										<li class="width-xl align-left text-overflow">{{ i.cname }}</li>
										<li :class="state == 0 ? 'width-icon i-but icon-but' : 'width-icon i-but'" @click="theSelected($event)">
											<i class="el-icon-document pointer view" @click="openDetails($event, i)" title="查看详情"></i>
											<i v-show="i.uid == 1 && state == 0" class="el-icon-delete pointer red-color" title="删除上报记录" @click="deleteEvent(i)"></i>
										</li>
										<li v-show="state == 1" class="but-width txt-but">
											<el-button size="mini" @click="preview($event, i)" title="预览报告">预览</el-button>
											<el-button size="mini" @click="downloadWord(i.rpid)" title="下载报告">下载</el-button>
										</li>
										<li v-show="state == 0" class="but-width txt-but" @click="theSelected($event)">
											<!-- tag  1 文字无操作（待处理）  6 预览下载  3 作报告  2 修改报告（弹窗样式和3一样） 4 文字无操作（无报告） 5 查看报告 （待审批） examine 有且为0隐藏重做   rpid 大于0显示预览和删除（tag等于2的时候） -->
											<div class="but-div">
												<el-button v-if="i.tag == 6 || i.tag == 2 || i.tag == 7 || i.tag == 8" size="mini" @click="preview($event, i)" title="预览报告">
													预览
												</el-button>
											</div>
											<div class="but-div">
												<el-button v-if="i.tag == 8" size="mini" @click="openReport($event, i, '重做')" title="重做报告">重做</el-button>
												<el-button v-if="i.tag == 3" size="mini" @click="openReport($event, i, '新增')" title="添加报告">新增</el-button>
												<el-button v-if="(i.tag == 5 || i.tag == 7) && i.examine == 0" size="mini" @click="examinationReport($event, i)" title="审批报告">
													审批
												</el-button>
												<span v-show="i.tag == 1">待处理</span>
												<span v-show="i.tag == 4">无报告</span>
												<span v-show="i.tag == 9">待重做</span>
											</div>
											<div class="but-div">
												<el-button v-if="i.tag == 2 || i.tag == 7 || i.tag == 8" size="mini" @click="deleteReport($event, i)" title="删除报告重做">
													删除
												</el-button>
											</div>
										</li>
										<li class="time">{{ i.ctime }}</li>
									</ul>
								</vue-scroll>
								<noData v-if="!item.total" />
							</div>
						</div>
						<div class="bottom-box">
							<div class="sizeBox">
								<span>单页显示条数</span>
								<input type="number" v-model="changeSize" autocomplete="off" />
							</div>

							<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
						</div>
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="but-box">
			<div class="userName-box">
				<span class="welcome-txt">您好，</span>
				<el-dropdown trigger="click" @command="dropDownMenu">
					<span class="el-dropdown-link">
						<span class="userName text-overflow">{{ userName }}</span>
						<i class="el-icon-caret-bottom el-icon-caret-top pointer"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item icon="el-icon-edit" command="修改密码">修改密码</el-dropdown-item>
						<el-dropdown-item icon="el-icon-switch-button" command="退出">退出</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 事件列表详情 -->
		<el-dialog title="事件详情" :visible.sync="dialogDetails" custom-class="detailsDialog" :modal="false">
			<div class="details">
				<div class="cell-box">
					<div class="cell">
						<p class="title">项目名称：</p>
						<p class="content text-overflow">{{ editorInfo.pjname }}</p>
					</div>
					<div class="cell">
						<p class="title">爆破单位：</p>
						<p class="content text-overflow">{{ editorInfo.bcname }}</p>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">检测单位：</p>
						<p class="content text-overflow">{{ editorInfo.cname }}</p>
					</div>
					<div class="cell">
						<p class="title">上报时间：</p>
						<p class="content text-overflow">{{ editorInfo.ctime }}</p>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">爆破时间：</p>
						<el-date-picker
							:class="editorShow ? 'input-w-xl' : 'input-w-xl show'"
							:readonly="!editorShow"
							v-model="editorInfo.dtime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyyMMddHHmm"
							type="datetime"
							:placeholder="editorShow ? '选择日期时间' : ''"
							@focus="setTime"
							popper-class="reportTime"
						></el-date-picker>
					</div>
					<div class="cell">
						<p class="title">
							<span class="letter">天气</span>
							：
						</p>
						<el-select v-show="editorShow" v-model="editorInfo.weather" clearable placeholder="请选择">
							<el-option v-for="item in weatherList" :key="item.value" :label="item.name" :value="item.name"></el-option>
						</el-select>
						<span v-show="!editorShow">
							<i v-show="editorInfo.weather.indexOf('晴') != -1" class="iconfont sunny icon-qing"></i>
							<i v-show="editorInfo.weather.indexOf('阴') != -1" class="iconfont Yin icon-yintian"></i>
							<i v-show="editorInfo.weather.indexOf('雨') != -1" class="iconfont rain icon-baoyudaodabaoyu"></i>
						</span>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">起爆坐标：</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.coordinates"
						>
							<el-button size="mini" slot="append" icon="el-icon-location-outline" @click="getLocation"></el-button>
						</el-input>
					</div>
					<div class="cell">
						<p class="title">位置描述：</p>
						<el-input :placeholder="editorShow ? '请输入' : ''" :class="editorShow ? '' : 'show'" :readonly="!editorShow" v-model="editorInfo.location"></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">爆破类型：</p>
						<el-select :class="editorShow ? '' : 'show'" :disabled="!editorShow" v-model="editorInfo.explosive" clearable :placeholder="editorShow ? '请选择' : ''">
							<el-option v-for="item in typesOfExplosiveList" :key="item.value" :label="item.name" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<p class="title">爆破方式：</p>
						<el-select :class="editorShow ? '' : 'show'" :disabled="!editorShow" v-model="editorInfo.mode" clearable :placeholder="editorShow ? '请选择' : ''">
							<el-option v-for="item in detonationWayList" :key="item.value" :label="item.name" :value="item.value"></el-option>
						</el-select>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell long-title">
						<p class="title">单段最大装药量（kg）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.md"
						></el-input>
					</div>
					<div class="cell long-title-s">
						<p class="title">爆破总药量（kg）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.ad"
						></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell-s">
						<p class="title">炮孔（个）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.hn"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">孔深（m）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.hd"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">孔径（mm）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.diameter"
						></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell-s">
						<p class="title">孔距（m）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.distance"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">排距（m）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.rd"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">填塞（m）</p>
						<el-input
							type="number"
							:placeholder="editorShow ? '请输入' : ''"
							:class="editorShow ? '' : 'show'"
							:readonly="!editorShow"
							v-model="editorInfo.fl"
						></el-input>
					</div>
				</div>
				<div class="img-box">
					<el-image v-show="!editorShow" fit="scale-down" id="sceneImg" style="width: 100%;height: 100%;" :src="imgList" :preview-src-list="[imgList]">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<div v-show="editorShow"><upload-img ref="uploadImg" :configuration="parameterOne"></upload-img></div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button v-show="!editorShow && allowedToChange" @click="editorShow = true">修改</el-button>
				<el-button v-show="editorShow" @click="confirmEditor">确认</el-button>
				<el-button type="primary" @click="dialogDetails = false">关闭</el-button>
			</div>
		</el-dialog>
		<!-- 生成报告页面 -->
		<el-dialog :title="dialogReportName" :visible.sync="dialogReport" custom-class="dialogReport" :modal="false" :modal-append-to-body="false" @close="closeRenerateReport">
			<div class="report-box">
				<div class="info">
					<div class="cell-box">
						<div class="cell">
							<p class="title">项目名称：</p>
							<p class="content text-overflow">{{ info.pjname }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破单位：</p>
							<p class="content text-overflow">{{ info.pjname }}</p>
						</div>
						<div class="cell">
							<p class="title">检测单位：</p>
							<p class="content text-overflow">{{ info.cname }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破时间：</p>
							<p class="content text-overflow">{{ info.dtime }}</p>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell">
							<p class="title">起爆坐标：</p>
							<p class="content text-overflow">{{ info.coordinates }}</p>
						</div>
						<div class="cell">
							<p class="title">位置描述：</p>
							<p class="content text-overflow">{{ info.location }}</p>
						</div>
						<div class="cell">
							<p class="title">上报时间：</p>
							<p class="content text-overflow">{{ info.ctime }}</p>
						</div>
						<div class="cell">
							<p class="title">天气：</p>
							<p class="content text-overflow">{{ info.weather }}</p>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell">
							<p class="title">爆破类型：</p>
							<p class="content text-overflow">{{ typesOfExplosiveList[info.explosive].name }}</p>
						</div>
						<div class="cell">
							<p class="title">爆破方式：</p>
							<p class="content text-overflow">{{ detonationWayList[info.mode].name }}</p>
						</div>
						<div class="cell long-title">
							<p class="title">单段最大装药量（kg）</p>
							<p class="content text-overflow">{{ info.md }}</p>
						</div>
						<div class="cell long-title-s">
							<p class="title">爆破总药量（kg）</p>
							<p class="content text-overflow">{{ info.ad }}</p>
						</div>
					</div>
					<div class="cell-box">
						<div class="cell-s">
							<p class="title">炮孔（个）</p>
							<p class="content text-overflow">{{ info.hn }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔深（m）</p>
							<p class="content text-overflow">{{ info.hd }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔径（mm）</p>
							<p class="content text-overflow">{{ info.diameter }}</p>
						</div>
						<div class="cell-s">
							<p class="title">孔距（m）</p>
							<p class="content text-overflow">{{ info.distance }}</p>
						</div>
						<div class="cell-s">
							<p class="title">排距（m）</p>
							<p class="content text-overflow">{{ info.rd }}</p>
						</div>
						<div class="cell-s">
							<p class="title">填塞（m）</p>
							<p class="content text-overflow">{{ info.fl }}</p>
						</div>
					</div>
				</div>
				<div class="list"><history ref="history"></history></div>
			</div>
		</el-dialog>
		<!-- 报告预览 -->
		<div class="preview-box Mask-box" v-if="previewStatus">
			<div class="pop-ups-content box-center">
				<div class="mian-box">
					<div class="left-box"><iframe frameborder="0" scrolling="auto" :src="src" width="1120px" height="100%" /></div>
					<div class="right-box">
						<el-button v-show="examinationShow" size="small" @click="examination(1)">
							<div class="but-content">
								<span class="examination el-icon-s-order"></span>
								<span class="txt">审批通过</span>
							</div>
						</el-button>
						<el-button v-show="examinationShow" size="small" @click="examination(2)">
							<div class="but-content">
								<span class="examination el-icon-s-release"></span>
								<span class="txt">审批驳回</span>
							</div>
						</el-button>
						<el-button size="small" @click="downloadPDF">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span class="txt">导出PDF</span>
							</div>
						</el-button>
						<el-button size="small" @click="downloadWord(rpid)">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span class="txt">导出word</span>
							</div>
						</el-button>
						<el-button size="small" @click="cancel">
							<div class="but-content">
								<span class="icon iconfont el-icon-close close"></span>
								<span class="txt">关闭</span>
							</div>
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 密码修改 -->
		<el-dialog title="修改密码" :visible.sync="dialogPassword" width="400px" :close-on-click-modal="false">
			<el-form :model="formPassword">
				<el-form-item label="原密码"><el-input show-password v-model="formPassword.old" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="新密码"><el-input show-password v-model="formPassword.password" autocomplete="off"></el-input></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogPassword = false">取 消</el-button>
				<el-button type="primary" @click="changePassword">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// 报告弹窗测点列表页面
import history from '../views/escalationList/index.vue';
// 图片上传
import uploadImg from '../components/uploadImg.vue';
export default {
	name: '',
	data() {
		return {
			activeIndex: '1', //项目内部选中菜单
			activeIndexs: '1', //平台后台设置选中菜单
			menuOne: [], //项目内部菜单
			deformation: [], //项目内部变形菜单
			vibration: [], //项目内部振动菜单
			menuTwo: [], //平台后台设置菜单
			projectName: '', //项目名称
			parameter: {}, // 路由传递的参数
			routerType: 'vibration', // 项目内部菜单类型（振动：vibration 变形：deformation）
			pushNum: 0, //推送数量
			pushList: [], // 推送列表数据
			visible: false, //事件弹窗是否显示
			reportType: 1, // 事件页面类型 1（监管） 2（事件） 3（监测） 4（审批）
			typeStyle: 1, // 事件弹窗样式
			companyProjects: [], // 事件公司项目选择列表数据
			// reportList: [], //事件列表数据
			reportNum: 0, // 事件数据数量
			reportShow: false, //事件入口是否显示
			dialogPassword: false, // 修改密码弹窗是否显示
			formPassword: {
				old: '',
				password: ''
			},
			item: {
				rows: [],
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'event',
				dataType: 'list',
				text: '暂时没有数据哦--'
			}, //事件列表数据
			state: null, // 事件列表状态筛选
			changeSize: 30, //单页显示条数
			dialogDetails: false, //事件详情弹窗是否显示
			editorShow: false, // 是否编辑
			allowedToChange: false, // 是否允许修改
			//展示信息数据
			info: {
				dtime: '', // 爆破时间
				weather: '', // 天气
				location: '', // 爆破位置
				coordinates: '', // 坐标
				lng: '',
				lat: '',
				diameter: 0, // 孔径
				hn: 0, // 炮孔
				hd: 0, // 孔深
				fl: 0, // 填塞
				distance: 0, // 孔距
				rd: 0, // 排距
				md: 0, // 单段最大装药量
				ad: 0, // 爆破总药量
				explosive: 0, //  炸药类型
				mode: 0, // 起爆方式
				ctime: '', // 上报时间
				cname: '', // 检测单位
				pjname: '', // 项目名称
				bcname: '' // 爆破单位
			},
			//编辑信息数据
			editorInfo: {
				dtime: '', // 爆破时间
				weather: '', // 天气
				location: '', // 爆破位置
				coordinates: '', // 坐标
				lng: '',
				lat: '',
				diameter: 0, // 孔径
				hn: 0, // 炮孔
				hd: 0, // 孔深
				fl: 0, // 填塞
				distance: 0, // 孔距
				rd: 0, // 排距
				md: 0, // 单段最大装药量
				ad: 0, // 爆破总药量
				explosive: 0, //  炸药类型
				mode: 0, // 起爆方式
				ctime: '', // 上报时间
				cname: '', // 检测单位
				pjname: '', // 项目名称
				bcname: '', // 爆破单位
				id: null, //当前查看数据的id
				pn1: '' //图片地址
			},
			// 炸药类型选择数据列表
			typesOfExplosiveList: [
				{
					value: 0,
					name: '露天深孔爆破'
				},
				{
					value: 1,
					name: '露天浅空爆破'
				},
				{
					value: 2,
					name: '地下爆破'
				},
				{
					value: 3,
					name: '拆除爆破'
				},
				{
					value: 4,
					name: '水下爆破'
				},
				{
					value: 5,
					name: '其他爆破'
				}
			],
			// 起爆方式选择数据列表
			detonationWayList: [
				{
					value: 0,
					name: '电力起爆法'
				},
				{
					value: 1,
					name: '导爆管起爆法'
				},
				{
					value: 2,
					name: '导爆索起爆法'
				},

				{
					value: 3,
					name: '混合起爆法'
				}
			],
			//天气信息
			weatherList: [
				{
					value: 0,
					name: '晴'
				},
				{
					value: 1,
					name: '阴'
				},
				{
					value: 2,
					name: '雨'
				}
				// {
				// 	value: 0,
				// 	name: '阴'
				// },
				// {
				// 	value: 1,
				// 	name: '晴'
				// },
				// {
				// 	value: 2,
				// 	name: '雾'
				// },
				// {
				// 	value: 3,
				// 	name: '晴转多云'
				// },
				// {
				// 	value: 4,
				// 	name: '雨'
				// },
				// {
				// 	value: 5,
				// 	name: '小雨'
				// },
				// {
				// 	value: 6,
				// 	name: '中雨'
				// },
				// {
				// 	value: 7,
				// 	name: '大雨'
				// },
				// {
				// 	value: 8,
				// 	name: '雷阵雨'
				// },
				// {
				// 	value: 9,
				// 	name: '暴雨'
				// },
				// {
				// 	value: 10,
				// 	name: '小雪'
				// },
				// {
				// 	value: 11,
				// 	name: '中雪'
				// },
				// {
				// 	value: 12,
				// 	name: '大雪'
				// },
				// {
				// 	value: 13,
				// 	name: '雨夹雪'
				// },
				// {
				// 	value: 14,
				// 	name: '冰雹'
				// }
			],
			parameterOne: {
				limit: 1, //最大文件个数
				name: 'add-img-one' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //事件图片上传组件参数
			imgList: '', //图片
			dialogReport: false, //生成报告弹窗是否显示
			dialogReportName: '新增报告', // 生成报告弹窗名字
			previewStatus: false, // 是否预览报告
			src: '', //报告预览地址
			rpid: '', //预览报告id
			examinationShow: false, // 审批按钮是否显示
			examinationData: {}, //审批参数
			// eventData: null,// 事件列表当前点击行数
			pushInter: null, // 推送列表定时请求对象
			reportInter: null // 事件列表定时请求对象
		};
	},
	created() {
		let query = this.$route.query;
		if (query.hasOwnProperty('routerType')) {
			this.routerType = query.routerType;
		} else {
			this.routerType = 'vibration';
		}
		var data = JSON.parse(localStorage.getItem('data'));
		var menuList = data.myAuthorities;
		// if(data.hasOwnProperty('ut')){
		// 	if(data.ut == 1){
		// 		this.reportShow = true;
		// 	}
		// }
		var lenOne = this.constData.pageUrlOne.length;
		var lenTwo = this.constData.pageUrlTwo.length;
		let menuTwo = [];
		let deformationPath = [];//允许跳转的变形菜单路由name
		menuList.forEach((item, index) => {
			if (item.id == 70) {
				this.reportShow = true;
			}
			for (let i = 0; i < lenOne; i++) {
				var val = this.constData.pageUrlOne[i];
				if (item.id != 1) {
					if (val.id == item.id) {
						this.menuOne.push(val);
						if (val.deformationShow) {
							this.deformation.push({
								...val,
								name: item.name
							});
							deformationPath.push(val.path);
						} else {
							this.vibration.push({
								...val,
								name: item.name
							});
						}
						return;
					}
				} else {
					return;
				}
			}
			for (let i = 0; i < lenTwo; i++) {
				var val = this.constData.pageUrlTwo[i];
				if (item.id != 1) {
					if (val.id == item.id) {
						if (val.id != 53 && val.id != 95 && val.id != 114) {
							this.menuTwo.push({
								...val,
								name: item.name
							});
						} else {
							menuTwo.push({
								...val,
								name: item.name
							});
						}
						return;
					}
				} else {
					return;
				}
			}
		});
		
		localStorage.setItem('deformationPath', deformationPath.toString());//存储
		
		let lenMT = menuTwo.length;
		for (let i = 0; i < lenMT; i++) {
			if (menuTwo[i].id == 95 || menuTwo[i].id == 114) {
				this.menuTwo.unshift(menuTwo[i]);
			}
		}
		for (let i = 0; i < lenMT; i++) {
			if (menuTwo[i].id == 53) {
				this.menuTwo.unshift(menuTwo[i]);
			}
		}
		
		setTimeout(() => {
			this.getPushList();
			this.pushInter = setInterval(() => {
				if (this.$route.name == 'login') {
					clearInterval(this.pushInter);
				} else {
					this.getPushList();
				}
			}, 60000);
		}, 1000);
		if (this.reportShow) {
			setTimeout(() => {
				this.getNum();
				this.reportInter = setInterval(() => {
					if (this.$route.name == 'login') {
						clearInterval(this.reportInter);
					} else {
						this.getNum();
					}
				}, 60000);
			}, 1500);
		}
	},
	mounted() {
		
	},
	computed: {
		menuShow() {
			//顶部返回键、退出键、导航是否显示
			return this.$store.state.menuShow;
		},
		childBackShow() {
			//顶部子页面返回按钮名称
			return this.$store.state.childBackShow;
		},
		menuType: {
			//顶部菜单栏是否显示
			get: function() {
				return this.$store.state.menuType;
			},
			set: function(newValue) {
				return newValue;
			}
		},
		projectList() {
			//项目搜索框列表数据
			return this.$store.state.projectList;
		},
		equipmentList() {
			//设备搜索框列表数据
			return this.$store.state.equipmentList;
		},
		userName() {
			// 登录用户名称
			var info = this.$store.state.userName || localStorage.getItem('userName');
			return info;
		}
	},
	methods: {
		goBack() {
			this.toRouter('index');
		},
		fallback() {
			if (this.childBackShow == '工具') {
				this.toRouter('tool');
			}
		},
		//项目内部菜单切换
		handleSelect(key, keyPath) {
			if (this.activeIndex != keyPath[0]) {
				this.menuOne.forEach(i => {
					if (i.id == key) {
						let path = i.path;
						// if (i.hasOwnProperty('deformationPath')) {
						// 	if (this.routerType == 'deformation') {
						// 		path = i.deformationPath;
						// 	}
						// }
						// // console.log(path);
						this.toRouter(path, this.parameter, 'query');
					}
				});
				// this.activeIndex = keyPath[0];
			}
		},
		//平台后台设置菜单切换
		handleSelects(key, keyPath) {
			// // console.log(key, keyPath);
			this.parameter = {};
			if (this.activeIndexs != keyPath[0]) {
				this.menuTwo.forEach(i => {
					if (i.id == key) {
						this.toRouter(i.path);
					}
				});
			}
		},
		// 进入后台管理页面
		goBackground() {
			if (this.menuTwo.length != 0) {
				this.toRouter(this.menuTwo[0].path);
			}
		},
		// 获取推送列表
		getPushList() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('web2/cmn/odnr/gpm', data).then(res => {
				if (res.status) {
					this.pushNum = res.data.length;
					this.pushList = res.data;
				}
			});
		},
		// 打开推送列表内容跳转页面
		openPush(i) {
			for (let n = 0; n < this.pushNum; n++) {
				if (this.pushList[n].id == i.id) {
					this.pushList.splice(n, 1);
					break;
				}
			}
			this.pushNum = this.pushList.length;
			var data = {
				name: '',
				id: '',
				img: '',
				pid: i.pid,
				pushId: i.id,
				fileTime: i.fileTime
			};
			this.axios.post('web2/cmn/odnr/gpjin', { pid: i.pid }).then(res => {
				if (res.status) {
					if (data != null) {
						data.name = res.data.name;
						data.id = res.data.id;
						data.img = res.data.pn1;
						if (i.mtp == 1) {
							// 关联
							this.toRouter('dataManagement', data, 'query');
						} else if (i.mtp == 2) {
							// 报警
							this.toRouter('alarm', data, 'query');
						}
					} else {
						if (i.mtp == 1) {
							// 关联
							this.$message({
								message: '暂无关联数据！',
								type: 'warning'
							});
						} else if (i.mtp == 2) {
							// 报警
							this.$message({
								message: '暂无报警数据！',
								type: 'warning'
							});
						}
					}
				}
			});
		},
		// 一键清除推送列表
		clearPush() {
			this.axios.get('/web2/cmn/odnr/cmi').then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '清除成功！',
						type: 'success'
					});
					this.pushNum = 0;
					this.pushList = [];
				}
			});
		},
		// 获取事件条数
		getNum() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('/web2/upblas/odnr/guin', data).then(res => {
				this.reportNum = 0;
				if (res.status) {
					this.reportNum = res.data;
				}
			});
		},
		// 打开事件弹窗
		openEvent() {
			this.visible = !this.visible;
			if (!this.visible) {
				this.closeReport();
				// // console.log(1111);
			} else if (this.visible) {
				// this.state
				this.getReportList();
			}
		},
		// 获取事件信息列表
		getReportList() {
			var data = {
				page: this.item.page,
				size: this.item.size
				// cid: '',//公司id
				// pjid: '',//项目id
				// type: '',
			};
			if (this.state != null) {
				data.type = this.state;
			}
			this.item.rows = [];
			this.item.total = 0;
			this.item.allPage = 0;
			this.axios.post('web2/upblas/odnr/glist', data).then(res => {
				this.item.rows = [];
				this.item.total = 0;
				this.item.allPage = 0;
				this.state = null;
				if (res.status) {
					this.item.rows = res.data.array;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / this.item.size);
					this.state = res.data.type;
					$(() => {
						$('.reportListDialog .conent-ul:odd').css({
							backgroundColor: '#f2f6fc'
						});
					});
				}
			});
		},
		// 事件信息刷新
		refreshData() {
			this.item.page = 1;
			this.item.size = 30;
			this.changeSize =30;
			this.getReportList();
		},
		// 当前操作数据更改样式
		theSelected(e) {
			$('.report-list-box .conent-ul').removeClass('selected-data');
			$(e.currentTarget)
				.parent()
				.addClass('selected-data');
		},
		// 打开事件详情弹窗
		openDetails(e, data) {
			// console.log(data);
			if (e != null) {
				$(e.currentTarget).css({
					color: '#409EFF'
				});
			}
			this.imgList = '';
			for (let key in this.editorInfo) {
				if (key != 'dtime') {
					this.editorInfo[key] = data[key];
				} else {
					var Y = data[key].slice(0, 4);
					var M = data[key].slice(5, 7);
					var D = data[key].slice(8, 10);
					var h = data[key].slice(12, 14);
					var m = data[key].slice(15, 17);
					// // console.log(Y, M, D, h, m);
					this.editorInfo[key] = Y + M + D + h + m;
				}
			}
			this.editorInfo.coordinates = data.lng + ',' + data.lat;
			if (data.pn1 != '') {
				this.imgList = this.constData.imgUrl + 'res/static/bepc/' + data.pn1;
			}
			this.allowedToChange = data.uid == 1;
			this.dialogDetails = true;
			this.editorShow = false;
			setTimeout(() => {
				this.$refs.uploadImg.clearFile();
			}, 300);
		},
		// 确认编辑
		confirmEditor() {
			// 上报add
			var formData = new FormData();
			for (let key in this.editorInfo) {
				if (key != 'coordinates' && key != 'cname' && key != 'pjname' && key != 'bcname' && key != 'ctime' && key != 'pn1' && key != 'lng' && key != 'lat') {
					if (key != 'diameter' && key != 'hn' && key != 'hd' && key != 'fl' && key != 'distance' && key != 'rd') {
						var v = this.editorInfo[key].toString();
						if (v == '') {
							this.$message({
								message: '请填写必填字段！',
								type: 'error'
							});
							return;
						} else if (key == 'location') {
							if (this.editorInfo.location.length > 20) {
								this.$message({
									message: '爆破位置不能超过20个字！',
									type: 'error'
								});
								return;
							}
						}
					}
					formData.append(key, this.editorInfo[key]);
				}
			}
			var coordinates = this.editorInfo.coordinates.split(',');
			if (coordinates.length != 0) {
				formData.append('lng', coordinates[0]);
				formData.append('lat', coordinates[1]);
			}
			var file = this.$refs.uploadImg.getFile();
			// // console.log(file);
			if (this.editorInfo.pn1 == '') {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			} else if (this.$refs.uploadImg.getJudgeData()) {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			}
			// // console.log(formData);
			this.axios.post('web2/upblas/cmc/mdinfo', formData).then(res => {
				if (res.status) {
					this.getReportList();
					this.dialogDetails = false;
					this.editorShow = false;
					this.$message({
						message: '修改成功！',
						type: 'success'
					});
				}
			});
		},
		// 打开生成报告弹窗
		openReport(e, data, v) {
			for (let key in data) {
				this.info[key] = data[key];
			}
			if (v == '新增') {
				this.dialogReportName = '新增报告';
			} else if (v == '重做') {
				this.dialogReportName = '重做报告';
			}
			this.info.coordinates = data.lng + ',' + data.lat;
			this.dialogReport = true;
			setTimeout(() => {
				this.$refs.history.setList({
					id: data.id,
					e: e
				});
			}, 300);
		},
		// 关闭生成报告弹窗
		closeGenerateReport() {
			this.dialogReport = false;
		},
		// 关闭生成报告弹窗执行事件
		closeRenerateReport() {
			this.$refs.history.reductionPage();
		},
		// 删除事件
		deleteEvent(val = {}) {
			if (JSON.stringify(val) == '{}') {
				return;
			}
			var data = {
				id: val.id
			};
			this.$confirm('此操作将永久删除该事件, 是否继续?', '警告', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.axios.post('web2/upblas/cmc/duinfo', data).then(res => {
						if (res.status) {
							this.getReportList();
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						}
					});
				})
				.catch(() => {});
		},
		// 删除报告
		deleteReport(e, val = {}) {
			if (JSON.stringify(val) == '{}') {
				return;
			}
			$(e.currentTarget).css({
				color: '#409EFF'
			});
			var data = {
				id: val.id
			};
			this.$confirm('此操作将永久删除该报告, 是否继续?', '警告', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.axios.post('web2/upblas/mmo/derp', data).then(res => {
						if (res.status) {
							this.getReportList();
							this.dialogDetails = false;
							this.editorShow = false;
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						}
					});
				})
				.catch(() => {});
		},
		// 审批报告
		examinationReport(e, val = {}) {
			this.examinationShow = true;
			this.examinationData = val;
			this.preview(e, val);
		},
		// 通过 1/驳回 2
		examination(type) {
			var data = {
				id: this.examinationData.id,
				type: type
			};
			this.axios.post('web2/upblas/emx/erp', data).then(res => {
				if (res.status) {
					this.$message({
						message: type == 1 ? '审批成功！' : '驳回成功！',
						type: 'success'
					});
					this.getReportList();
					this.cancel();
				}
			});
		},
		// 预览报告
		preview(e, value) {
			if (e != null) {
				$(e.currentTarget).css({
					color: '#901fbd'
				});
			}

			this.rpid = value.rpid;
			var val = {
				id: value.rpid
			};
			this.axios.post('web2/upblas/odnr/srp', val, { responseType: 'arraybuffer' }).then(res => {
				// // console.log(res);
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					this.src = href;
					this.previewStatus = true;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					// // console.log(reader);
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 下载PDF
		downloadPDF() {
			const link = document.createElement('a');
			link.style.display = 'none';
			link.href = this.src;
			link.setAttribute('download', this.fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		// 下载Word
		downloadWord(val = {}) {
			if (JSON.stringify(val) == '{}') {
				return;
			}
			this.downloadFile(val);
		},
		// 关闭预览
		cancel() {
			this.previewStatus = false;
			if (this.examinationShow) {
				this.examinationShow = false;
			}
		},
		//批量下载源文件并打包
		downloadFile(value) {
			var data = {
				params: {
					ids: value,
					responseType: 'arraybuffer'
				}
			};

			this.axios.get('web2/upblas/odnr/dwrp', data).then(res => {
				// // console.log(res);
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					const data = res;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转json
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						// console.log(type);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 用户下拉菜单事件
		dropDownMenu(val) {
			// // console.log(val)
			if (val == '退出') {
				this.exitLogin();
			} else if (val == '修改密码') {
				for (let k in this.formPassword) {
					this.formPassword[k] = '';
				}
				this.dialogPassword = true;
			}
		},
		// 退出登录
		exitLogin() {
			this.axios.post('logout').then(res => {
				// if (res.status) {
				// }
			});
			setTimeout(() => {
				localStorage.setItem('lInfo', '');
				localStorage.setItem('userName', '');
				localStorage.setItem('data', '');
				this.toRouter('login');
			}, 200);
		},
		// 修改密码
		changePassword() {
			var data = {
				...this.formPassword
			};
			this.axios.post('web2/user/odnr/mmn', data).then(res => {
				if (res.status) {
					this.$message({
						type: 'success',
						message: '密码修改成功'
					});
					this.dialogPassword = false;
				}
			});
		},
		//事件翻页组件触发分页回调
		currentPageChange(type) {
			// // console.log(type)
			if (type === 'event') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getReportList();
				// // console.log(123)
			}
		},
		// 设置时间
		setTime() {
			// this.info.time = new Date().format('yyyy-MM-dd hh:mm:ss');
		},
		// 定位
		getLocation() {
			var that = this;
			var toast = this.$loading({
				lock: true,
				text: '定位中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			// 高德地图
			var map, geolocation;
			//加载地图，调用浏览器定位服务
			map = new AMap.Map('container', {
				resizeEnable: true,
				noGeoLocation: 1
			});
			map.plugin('AMap.Geolocation', function() {
				geolocation = new AMap.Geolocation({
					enableHighAccuracy: true, //是否使用高精度定位，默认:true
					timeout: 15000, //超过15秒后停止定位，默认：无穷大
					showButton: false, //显示定位按钮，默认：true
					showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
					showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
					panToLocation: false //定位成功后将定位到的位置作为地图中心点，默认：true
				});
				map.addControl(geolocation);
				geolocation.getCurrentPosition();
				AMap.event.addListener(geolocation, 'complete', data => {
					toast.close();
					// // console.log(data);
					that.editorInfo.coordinates = data.position.lng + ',' + data.position.lat;
					that.editorInfo.lng = data.position.lng;
					that.editorInfo.lat = data.position.lat;
				}); //返回定位信息
				AMap.event.addListener(geolocation, 'error', error => {
					toast.close();
					// // console.log(error);
					that.$message({
						message: '定位失败！',
						type: 'error'
					});
				}); //返回定位出错信息
			});
		},
		// 事件列表判断每行样式
		setLineStyle(data, index) {
			var cName = '';
			if (index == this.item.rows.length - 1) {
				cName = 'conent-ul last-child';
			} else {
				cName = 'conent-ul';
			}
			return cName;
		}
	},
	components: {
		history,
		uploadImg
	},
	beforeDestroy() {
		if (this.pushInter != null) {
			clearInterval(this.pushInter);
		}
		if (this.reportInter != null) {
			clearInterval(this.reportInter);
		}
	},
	watch: {
		$route(to, form) {
			this.visible = false;
			// // console.log(to);
			if (to.name == 'login') {
				if (this.pushInter != null) {
					clearInterval(this.pushInter);
				}
				if (this.reportInter != null) {
					clearInterval(this.reportInter);
				}
			}
			if (to.meta.menuType == 1) {
				var len = this.menuOne.length;
				for (let i = 0; i < len; i++) {
					var item = this.menuOne[i];
					if (to.name == item.path) {
						this.activeIndex = item.id.toString();
						break;
					}
				}
				this.$forceUpdate();
			} else if (to.meta.menuType == 2) {
				var len = this.menuTwo.length;
				for (let i = 0; i < len; i++) {
					var item = this.menuTwo[i];
					if (to.path.indexOf('/tool/') != -1) {
						if (item.path == 'tool') {
							this.activeIndexs = item.id.toString();
							break;
						}
					} else {
						if (to.name == item.path) {
							this.activeIndexs = item.id.toString();
							break;
						}
					}
				}
			}
			if (JSON.stringify(to.query) != '{}') {
				if (to.query.hasOwnProperty('name')) {
					this.projectName = to.query.name;
				} else {
					this.projectName = '';
				}
				for (let key in to.query) {
					if (key != 'status' && key != 'pushId' && key != 'fileTime' && key != 'pointId' && key != 'pid') {
						this.parameter[key] = to.query[key];
					}
				}
			} else {
				this.projectName = '';
			}
			if (to.query.hasOwnProperty('routerType')) {
				this.routerType = to.query.routerType;
			} else {
				this.routerType = 'vibration';
			}
		}
	}
};
</script>
<!-- 事件、推送列表、事件详情样式 -->
<style lang="scss">
// 取消滚动条
.el-scrollbar__wrap {
	overflow: auto;
}

// dialog弹窗关闭按钮
.el-dialog__headerbtn {
	i {
		font-size: 20px;
	}
}

// 推送列表
.push-list-popover {
	top: 45px !important;

	.push-box {
		width: 100%;
		height: 330px;

		.top-but-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			.title-left {
				font-size: 17px;
				font-weight: 600;
				color: #000000;
			}
		}

		.scroll-box {
			height: calc(100% - 45px);

			.scroll-list {
				width: 100%;
				.card-box {
					width: 100%;
					margin: 0 0 12px 0;
					border-radius: 6px;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.yellow-color {
						color: #fff702;
					}

					.green-color {
						color: #00aa00;
					}

					.red-color {
						color: #f70000;
					}

					.card-name {
						width: 20px;

						i {
							font-size: 18px;
						}
					}

					.content-box {
						width: calc(100% - 36px);
						padding: 6px 8px;
						background-color: #dcdfe6;

						.card-content {
							width: 100%;
							font-size: 13px;
							color: #000000;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
	}
}

// 推送列表清空提示弹窗
.push-popconfirm {
	top: 83px !important;

	.el-popconfirm__main {
		margin-bottom: 10px;
	}
}

// 事件列表弹窗
.reportListDialog {
	width: 100% !important;
	height: calc(100% - 58px);
	margin: 0 !important;
	margin-top: 56px !important;

	.dialog-title {
		width: calc(100% - 100px);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 16px;
			font-weight: 600;
			color: #fff;
		}
	}

	.el-dialog__body {
		width: 100%;
		height: calc(100% - 66px);
		padding: 10px 0px 0 0px;
	}

	.report-list-box {
		width: 100%;
		// height: 300px;
		height: 100%;
		position: relative;

		// 审批通过颜色
		.through {
			background-color: rgba($color: #67c23a, $alpha: 0.1);
		}
		// 审批不通过颜色
		.notThrough {
			background-color: rgba($color: #e6a23c, $alpha: 0.1);
		}

		// 当前操作样式
		.selected-data {
			background-color: rgba($color: #c8c8c8, $alpha: 1) !important;
		}

		.blue-color {
			color: #00a5ec;
		}

		.red-color {
			color: #f56c6c;
		}

		.screening {
			width: calc(100% - 30px);
			padding: 0 15px 10px 15px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.el-radio-button__inner {
				padding: 6px 10px;
			}
		}

		.instructions {
			position: absolute;
			top: -33px;
			right: 160px;
			color: #fff;
			transform: rotate(60deg);
		}

		.list-scroll-box {
			width: 100%;
			height: calc(100% - 38px);
			padding: 0 0 0 0;

			.__view {
				width: 100%;
				height: 100%;
			}

			.list-box {
				width: 100%;
				height: calc(100% - 55px);

				ul {
					width: calc(100% - 30px);
					padding: 0 15px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top: thin solid #dcdfe6;

					li {
						text-align: center;
					}

					.align-left {
						text-align: left;
					}

					.time {
						width: 150px;
					}

					.time-l {
						width: 180px;
					}

					.width-s {
						width: 60px;
					}

					.width-icon {
						width: 50px;

						i {
							font-size: 20px;
						}
					}

					.width-l {
						width: 100px;
					}

					.width-xl {
						width: 180px;
					}

					.but-width {
						width: 180px;
					}

					.icon-but {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.i-but {
						.view {
							font-weight: 600;
							color: #3a8f6a;
						}
					}

					.txt-but {
						display: flex;
						justify-content: center;
						align-items: center;

						.el-button {
							padding: 5px 8px;
						}

						.but-div {
							min-width: 46px;
							height: auto;
							margin-left: 10px;
						}

						.but-div:first-child {
							margin-left: 0px;
						}
					}
				}

				.head-ul {
					height: 40px;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
					background-color: #ebeef5;
				}

				.scroll-box {
					width: 100%;
					height: calc(100% - 40px);

					.conent-ul {
						height: 39px;
						// padding: 10px;
						font-size: 14px;
						color: #606266;
					}

					.last-child {
						border-bottom: thin solid #dcdfe6;
					}

					// .conent-ul:nth-child(even) {
					// 	background-color: #f2f6fc;
					// }

					.conent-ul:hover {
						background-color: #ebeef5;
					}
				}
			}

			.bottom-box {
				position: relative;
				width: 100%;
				height: 48px;
				-webkit-box-flex: 0;
				-ms-flex: 0 0 48px;
				flex: 0 0 48px;

				.sizeBox {
					float: left;
					line-height: 30px;
					margin: 9px 10px 0 30px;

					input {
						width: 70px;
						text-indent: 0;
						text-align: center;
						margin: 0 5px;
						line-height: 24px;
						height: 24px;
						border-radius: 2px;
						border: 1px solid #e5e5e5;
					}
				}

				.tableFooter {
					width: auto;
					display: inline-block;
					position: absolute;
					float: left;
					background: none;
				}
			}
		}
	}
}

// 事件弹窗时间选择隐藏此刻按钮
.reportTime .el-picker-panel__link-btn:first-child {
	display: none;
}
// 事件弹窗审批按钮弹窗
.but-popover {
	.popper__arrow {
		border-bottom-color: #ffffff !important;
		// top: -15px;
	}
}
// 事件详情
.detailsDialog {
	width: 600px !important;
	height: 700px;
	margin-top: 165px !important;

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}

	.el-dialog__body {
		width: calc(100% - 20px);
		height: calc(100% - 120px);
		padding: 10px 10px 0 10px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.details {
		width: 100%;
		height: 100%;

		// 展示内容
		.cell-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;

			.cell,
			.cell-s {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.title {
					width: 73px;
					font-size: 14px;
					color: #909399;

					.letter {
						display: inline-block;
						width: 71%;
						letter-spacing: 26px;
						white-space: nowrap;
					}
				}

				.content {
					width: calc(100% - 73px);
					font-size: 15px;
					color: #000;
				}

				.sunny {
					color: #ffd54f;
					font-size: 26px;
				}

				.Yin {
					color: #909399;
					font-size: 26px;
				}

				.rain {
					color: #b3dbff;
					font-size: 26px;
				}

				.el-select {
					width: calc(100% - 73px);

					.el-input {
						width: 100%;
					}

					.el-input__icon {
						line-height: 32px;
					}
				}

				.el-input {
					width: calc(100% - 73px);

					.el-input__inner {
						height: 32px !important;
						line-height: 32px;
						padding: 0 10px;
						font-size: 15px;
						color: #000000;
					}
				}

				.el-date-editor {
					.el-input__inner {
						padding: 0 30px;
					}

					.el-input__icon {
						line-height: 32px;
					}
				}
			}
			// 展示样式
			.show {
				.el-input__inner {
					border: none;
				}
				// .el-input__prefix,
				.el-input-group__append,
				.el-input__suffix {
					display: none;
				}

				.el-input.is-disabled .el-input__inner {
					background: none;
					cursor: text;
				}
			}

			.cell {
				width: calc(50% - 4px);
			}

			.cell-s {
				width: calc(33% - 4px);

				.title {
					width: 85px !important;
				}
			}

			.long-title {
				.title {
					width: 157px;
				}

				.el-input {
					width: calc(100% - 157px);
				}
			}

			.long-title-s {
				.title {
					width: 130px;
				}

				.el-input {
					width: calc(100% - 130px);
				}
			}
		}
		// 图片
		.img-box {
			width: 100%;
			height: 165px;
			display: flex;
			justify-content: center;
			align-items: center;

			.image-slot {
				height: 90%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				i {
					font-size: 50px;
				}

				span {
					font-size: 20px;
				}
			}
		}
	}
}
// 报告
.dialogReport {
	position: absolute !important;
	width: 1300px !important;
	height: 700px;
	top: 165px;
	left: 0;
	right: 0;
	margin: auto !important;

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}

	.el-dialog__body {
		width: 100%;
		height: calc(100% - 64px);
		padding: 10px 0px 0 0px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.report-box {
		width: 100%;
		height: 100%;
		// 展示内容
		.info {
			width: calc(100% - 20px);
			padding: 0 10px;

			.cell-box {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;

				.cell,
				.cell-s {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.title {
						width: 73px;
						font-size: 14px;
						color: #909399;
					}

					.content {
						width: calc(100% - 73px);
						font-size: 15px;
						color: #000;
					}

					.el-select {
						width: calc(100% - 73px);

						.el-input {
							width: 100%;
						}

						.el-input__icon {
							line-height: 32px;
						}
					}

					.el-input {
						width: calc(100% - 73px);

						.el-input__inner {
							height: 32px !important;
							line-height: 32px;
							padding: 0 10px;
							font-size: 15px;
							color: #000000;
						}
					}

					.el-date-editor {
						.el-input__inner {
							padding: 0 30px;
						}

						.el-input__icon {
							line-height: 32px;
						}
					}
				}

				.cell {
					width: calc(24% - 4px);
				}

				.cell-s {
					width: calc(15% - 4px);
					
					.title {
						width: 85px !important;
					}
				}

				.long-title {
					.title {
						width: 157px;
					}

					.el-input {
						width: calc(100% - 157px);
					}

					.content {
						width: calc(100% - 157px);
					}
				}

				.long-title-s {
					.title {
						width: 130px;
					}

					.el-input {
						width: calc(100% - 130px);
					}

					.content {
						width: calc(100% - 130px);
					}
				}
			}
		}
		// 测点列表
		.list {
			width: 100%;
			height: calc(100% - 160px);
		}
	}
}
</style>

<style scoped lang="scss">
.navMenu-box {
	width: 100%;
	height: 61px;
	background-color: #545c64;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	::v-deep {
		.el-menu-item {
			font-size: 16px;
		}

		.el-menu.el-menu--horizontal {
			border-bottom: none;
		}

		.el-page-header__left {
			color: #ffffff;

			.el-icon-back {
				font-size: 20px;
				color: #00aaff;
			}

			.el-page-header__title {
				color: #00aaff;
			}
		}

		.el-page-header__content {
			width: 500px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #ffffff;
			margin-right: 30px;
		}
		
		.backstage{
			.el-page-header__content {
				width: 300px !important;
			}
		}

		.el-button {
			font-size: 14px;
			font-weight: 500;
		}
	}

	.navMenu {
		// flex-shrink: 0;
		// min-width: 288px;

		::v-deep {
			.el-menu {
				display: inline-block;
			}

			.el-menu--horizontal > .el-menu-item {
				height: 63px;
				line-height: 75px;
			}
		}
	}
	.search-box {
		width: 580px;
		margin-left: 25px;
		// display: flex;
		// justify-content: space-between;
		// align-items: center;

		.name {
			font-size: 20px;
			color: #ffffff;
		}

		.user-box {
			width: 60px;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			align-items: center;
			// margin-left: 20px;

			.iconStyle {
				font-size: 30px;
				color: white;
			}

			.txt {
				font-size: 12px;
				color: white;
				// display: none;
				// margin-left: 15px;
			}
		}

		// .user-box:hover .txt {
		// 	display: inline-block !important;
		// }
	}

	.push-report {
		width: 80px;
		position: absolute;
		top: 14px;
		right: 200px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.badge-i-but {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
		}

		.badge {
			margin-top: -1px;
			color: #fff;

			.icon {
				font-size: 23px;
			}

			::v-deep {
				.el-badge__content {
					top: -2px;
					right: 6px;
				}

				.el-badge__content {
					border: none;
				}
			}
		}

		// 事件
		.report-icon {
			color: #ffffff;

			.icon {
				font-size: 23px;
			}

			::v-deep {
				.el-badge__content {
					top: -0.5px;
					right: 7px;
				}

				.el-badge__content {
					border: none;
				}
			}
		}
	}

	.but-box {
		width: 160px;
		position: absolute;
		top: 20px;
		right: 25px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.userName-box {
			.welcome-txt {
				font-size: 14px;
				color: #ffffff;
			}

			.userName {
				font-size: 16px;
				display: inline-block;
				max-width: 90px;
				cursor: pointer;
				margin-right: 6px;
			}

			::v-deep {
				.el-dropdown-link {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #ffffff;
				}
			}
		}
	}
	// 报告预览
	.preview-box {
		position: fixed;
		// display: none;
		z-index: 10000;

		.pop-ups-content {
			width: 1190px;
			height: 100%;
			margin-top: 10px;

			.mian-box {
				width: 100%;
				height: 98%;
				display: flex;

				.left-box {
					height: 100%;
				}

				.right-box {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					::v-deep {
						.el-button {
							padding: 0;
							width: 56px;
							height: 56px;
							margin-left: 14px;
							margin-bottom: 15px;
						}
					}

					.but-content {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						align-items: center;

						.icon {
							color: #409eff;
							font-size: 22px;
						}
						
						.close{
							font-size: 22px;
							font-weight: 600;
							color: #d80000;
						}

						.txt {
							font-size: 12px;
							margin-left: 0;
						}

						.examination {
							font-size: 26px;
							color: #409eff;
						}

						.iconhoutaitubiao-25 {
							font-size: 35px !important;
						}

						span:last-child {
							color: #000;
						}
					}
				}
			}
		}
	}
}
</style>
