<!--弹出框最外层-->
<template>
  <div class="layerContainerBox">
    <div class="layerBorder">
      <div class="layerMaxHeight">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "layerContainer",
  }
</script>

<style scoped>
  .layerContainerBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900001;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
    display: none;
  }

  .layerBorder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layerMaxHeight {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
