const vibrationPoint = resolve => require(["../views/measuringPoint/vibrationPoint.vue"], resolve); //振动测点
const deformationPoint = resolve => require(["../views/measuringPoint/deformationPoint.vue"], resolve); //变形测点

export default [{
		path: "/point",
		name: "point",
		component: vibrationPoint,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 振动测点管理
	{
		path: "/deformationPoint",
		name: "deformationPoint",
		component: deformationPoint,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 1, //顶部菜单类型
		}
	}, // 振动测点管理
]
