<template>
	<div class="previewChart">
		<div class="flex">
			<div class="content">
				<div class="baseInfo">
					<div class="left">{{ fileBaseData.fileTimeStamp }}</div>
					<ul class="center">
						<li :title="'测点编号：' + fileBaseData.pointName">测点编号：{{ fileBaseData.pointName }}</li>
						<li :title="'测点参数：' + fileBaseData.deviceParam">测点参数：{{ fileBaseData.deviceParam ? fileBaseData.deviceParam : '-' }}</li>
						<li :title="'企业名称：' + fileBaseData.companyName">企业名称：{{ fileBaseData.companyName ? fileBaseData.companyName : '-' }}</li>
						<li :title="'名称/位置：' + fileBaseData.address">名称/位置：{{ fileBaseData.address ? fileBaseData.address : '-' }}</li>
						<li :title="'设备编号：' + fileBaseData.deviceNum">设备编号：{{ fileBaseData.deviceNum ? fileBaseData.deviceNum : '-' }}</li>
						<li :title="'项目名称：' + fileBaseData.projectName">项目名称：{{ fileBaseData.projectName ? fileBaseData.projectName : '-' }}</li>
					</ul>
					<div class="right">
						<Button v-show="fileBaseData.hasOwnProperty('pageType') && fileBaseData.pageType == 1" type="associate" :button-callback="buttonCallback" text="关联" />
						<el-button type="success" v-if="briefingShow" @click="briefing">简报</el-button>
						<el-button class="modify" v-if="modifyDataShow" type="info" icon="el-icon-s-tools" title="修改数据" @click="dialogForm = true"></el-button>
						<Button type="create" v-show="fileBaseData.hasOwnProperty('report') && fileBaseData.report == 1" :button-callback="buttonCallback" text="生成报表" />
						<Button type="close" class="close" :button-callback="buttonCallback" text="关闭" />
					</div>
				</div>
				<div class="chartContainer"><div id="chartContainers"></div></div>
			</div>
		</div>
		<el-dialog title="修改数据" :visible.sync="dialogForm" width="345px" :modal-append-to-body="false" @close="dialogClose">
			<el-form :model="form" label-position="left" label-width="80px">
				<el-form-item label="X增减系数"><el-input type="number" v-model="form.rate1" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="Y增减系数"><el-input type="number" v-model="form.rate2" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="Z增减系数"><el-input type="number" v-model="form.rate3" autocomplete="off"></el-input></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogForm = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="ModifyData">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'previewChart',
	props: {
		// 修改数据接口url
		modifyDataUrl: {
			type: String,
			default: ''
		},
		// 修改数据按钮是否显示
		modifyDataShow: {
			type: Boolean,
			default: false
		},
		// 简报按钮是否显示
		briefingShow: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			fileBaseData: {},
			chartDom: null,
			dataId: '', //修改数据的数据id
			form: {
				rate1: 1, //修改数据的X增减系数
				rate2: 1, //修改数据的Y增减系数
				rate3: 1 //修改数据的Z增减系数
			},
			dialogForm: false, //修改数据弹窗
			option: {
				color: ['#d80000', '#1dcb32', '#1d22d9'],
				legend: {},
				tooltip: {
					trigger: 'axis',
					formatter: data => {
						let axisValue;
						let arr = data.map(value => {
							axisValue = value.axisValue;
							return {
								value: value.seriesName.split(' ')[0] + '：' + value.value,
								marker: value.marker
							};
						});
						return `<div>
                  <h5 style="font-size: 14px;font-weight: normal">${axisValue}</h5>
                  <p style=" display: ${arr[0] && arr[0].value ? 'block' : 'none'}">${arr[0] && arr[0].marker}${arr[0] && arr[0].value}${this.fileBaseData.units}</p>
                  <p style=" display: ${arr[1] && arr[1].value ? 'block' : 'none'}">${arr[1] && arr[1].marker}${arr[1] && arr[1].value}${this.fileBaseData.units}</p>
                  <p style=" display: ${arr[2] && arr[2].value ? 'block' : 'none'}">${arr[2] && arr[2].marker}${arr[2] && arr[2].value}${this.fileBaseData.units}</p>
               </div>`;
					}
				},
				grid: {
					left: 50,
					top: '30',
					right: '30',
					bottom: '30',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					name: 'T(s)',
					nameLocation: 'center',
					nameGap: '30',
					nameTextStyle: {
						fontWeight: 'bold',
						fontSize: 14
					},
					axisLabel: {
						formatter: function(value) {
							return Number(value);
						}
					}
				},
				yAxis: {
					type: 'value',
					min: -10,
					max: 10,
					boundaryGap: [0, '100%'],
					name: 'V(cm/s)',
					nameLocation: 'center',
					nameGap: 50,
					nameTextStyle: {
						fontWeight: 'bold',
						fontSize: 14
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: '#ccc'
						}
					},
					axisLabel: {
						show: true,
						formatter: value => {
							let value1 = this.toFixed(value, 1);
							let value2 = this.toFixed(value, 2);
							let value3 = this.toFixed(value, 3);
							let value4 = this.toFixed(value, 4);
							return value1 ? value1 : value2 ? value2 : value3 ? value3 : value4 ? value4 : '0.00';
						}
					}
				},
				animation: false,
				series: []
			}
		};
	},
	mounted() {},
	methods: {
		drawChart(data) {
			console.log(data);
			if (data.hasOwnProperty('dataId')) {
				this.dataId = data.dataId;
			}
			this.fileBaseData = data;
			const chartContainer = document.getElementById('chartContainers');
			let legend = [];
			legend.push('X [V=' + this.toFixed(data.maxXData, 4) + data.units + ' F=' + this.toFixed(data.mainFX, 1) + 'Hz]');
			legend.push('Y [V=' + this.toFixed(data.maxYData, 4) + data.units + ' F=' + this.toFixed(data.mainFY, 1) + 'Hz]');
			legend.push('Z [V=' + this.toFixed(data.maxZData, 4) + data.units + ' F=' + this.toFixed(data.mainFZ, 1) + 'Hz]');

			let max = data.maxData * 1.3;
			max = max === 0 ? 0.2 : max;
			// console.log(max);
			let fileType = data.fileName.split('.')[1];
			let chartType = 'line';

			this.option.legend.data = legend;
			this.option.xAxis.data = data.date;
			this.option.xAxis.axisLabel = {
				formatter: function(value) {
					return Number(value).toFixed(2);
				}
			};
			this.option.yAxis.name = 'V(' + data.units + ')'
			if (fileType === 'bin') {
				this.option.yAxis.min = -max;
				chartType = 'line';
				this.option.yAxis.axisLabel = {
					show: true,
					formatter: function(value) {
						return Number(value).toFixed(2);
					}
				};
			} else {
				this.option.yAxis.min = 0;
				chartType = 'bar';
				this.option.yAxis.name = '';
				this.option.yAxis.axisLabel = {
					show: true,
					formatter: function(value) {
						return Number(value).toFixed(2);
					}
				};
			}

			this.option.yAxis.max = max;

			this.option.series = [];

			for (let i = 0; i < data.series.length; i++) {
				this.calculate(data.series[i], data.sampleRate);

				this.option.series.push({
					name: legend[i],
					data: data.series[i],
					type: chartType,
					smooth: 0.2,
					symbol: 'none',
					animation: false,
					lineStyle: {
						width: 1
					}
				});
			}
			(this.option.toolbox = {
				show: false,
				feature: {
					dataZoom: {
						realtime: false,
						yAxisIndex: 'none'
					},
					restore: {}
				}
			}),
				(this.option.dataZoom = [
					{ type: 'inside' }, //用于添加滚轮缩放
					{ type: 'slider', show: false } //用于添加滑动条缩放，
				]);

			if (this.option && typeof this.option === 'object') {
				const chart = (this.chartDom = this.echarts.init(chartContainer));
				chart.clear();
				chart.setOption(this.option, true);
				this.$forceUpdate();
			}
			// // console.log(this.option, '123123');
		},
		// 修改数据
		ModifyData() {
			var data = {
				id: this.dataId,
				...this.form
			};
			this.axios.post(this.modifyDataUrl, data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '数据修改成功',
						type: 'success'
					});
					this.$emit('modifyData');
				}
			});
		},
		//计算主频
		calculate(data, rate) {
			let max = 0,
				index = 0,
				min = 0,
				type,
				sum = 0,
				mean = 0,
				i1 = 0,
				i2 = 0,
				len = data.length;
			//获取正数最大值
			max = Math.max(...data);
			//获取负数最小值
			min = Math.min(...data);
			//最大值和最小值之前取绝对值最大值为正数还是负数
			type = Math.abs(max) > Math.abs(min);

			//求和
			for (let i = 0; i < len; i++) {
				sum += data[i];
			}

			//计算均值
			mean = sum / len;

			//获取绝对值最大值所在的下标
			if (type) {
				data.map((value, i) => {
					if (value === max) {
						index = i;
					}
				});
			} else {
				data.map((value, i) => {
					if (value === min) {
						this.index = i;
					}
				});
			}

			//获取最大值之前越过平均值线的第一个点
			for (let i = index; i > 0; i--) {
				if (type) {
					if (data[i] <= mean) {
						i1 = i;
						break;
					}
				} else {
					if (data[i] >= mean) {
						i1 = i;
						break;
					}
				}
			}
			//获取最大值之后越过平均值线的第一个点
			for (let i = index; i < len; i++) {
				if (type) {
					if (data[i] <= mean) {
						i2 = i;
						break;
					}
				} else {
					if (data[i] >= mean) {
						i2 = i;
						break;
					}
				}
			}
			//计算主频
			let frequency = rate / (i2 - i1) / 2;
			frequency = Number(frequency.toFixed(2));
		},
		// 修改数据弹窗关闭
		dialogClose() {
			for (let k in this.form) {
				this.form[k] = 1;
			}
		},
		// 简报打开
		briefing(){
			this.$emit('briefingClick');
		},
		buttonCallback(type) {
			if (this.chartDom != null) {
				this.chartDom.clear();
			}
			this.$parent.preview = false;
			if (type === 'create') {
				this.$parent.showBriefReportLayer();
			} else if (type === 'associate') {
				let data = {
					fileTime: this.fileBaseData.fileTime,
					pid: this.fileBaseData.pid
				};
				this.$emit('associateData', data);
				$('.previewChart').hide();
			}
		}
	}
};
</script>

<style scoped lang="scss">
.previewChart {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 900020;
	// display: none;

	.flex {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content {
		width: 100%;
		height: 580px;
		max-height: 580px;
		overflow: hidden;
		background-color: #fff;
		display: flex;
		flex-flow: column;

		.baseInfo {
			flex: 0 0 80px;
			width: 100%;
			overflow: hidden;
			display: flex;
			border-bottom: 1px solid #ccc;

			.left {
				flex: 0 0 200px;
				line-height: 80px;
				text-align: center;
				font-weight: bold;
				font-size: 16px;
			}

			.right {
				flex: 0 0 280px;
				line-height: 80px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: space-around;

				::v-deep {
					.el-button {
						padding: 7px 10px;
					}
					
					.el-button + .el-button {
						margin-left: 0;
					}
				}
			}

			.right button.close {
				background-color: #e6a23c;
				border-color: #e6a23c;
			}

			.center {
				flex: 1 1 auto;
				width: 100%;
				border-left: 1px solid #ccc;
				border-right: 1px solid #ccc;
				padding: 10px;
				box-sizing: border-box;
				line-height: 30px;

				li {
					float: left;
					width: 33.33%;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}

		.chartContainer {
			width: 100%;
			flex: 1 1 auto;
			height: 100%;
			overflow: hidden;
			padding: 20px;
			box-sizing: border-box;

			> div {
				width: 100%;
				height: 100%;
				overflow: hidden;
			}
		}
	}
}
</style>
