import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  headerShow: true,//顶部状态栏是否显示
	  menuShow: false,//顶部菜单栏是否显示
	  childBackShow: '',//顶部子页面返回按钮名称
	  menuType: 1,//顶部菜单类型
	  projectList:[],//项目页面搜索框列表数据
	  equipmentList:[],//设备页面搜索框列表数据
	  userName: '',//登录用户名称
	  unit: 'cm/s',//平台单位
  },
  mutations: {},
  actions: {},
  modules: {}
});
