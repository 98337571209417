<template>
  <button v-if="!showbtn" :class=" disabled ? 'disabled' : '' " :title="  disabled ? '您没有权限' : ''  " @click="clickButton(type, $event)"
    :style="{'min-width': width+'px', height: height+'px', 'line-height': height+'px'}" :type="type">{{ text }}</button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: [String, Number],
    width: [String, Number],
    height: [String, Number],
    type: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonCallback: {
      type: Function,
      required: false
    },
    showbtn: true
  },
  methods: {
    clickButton (type, e) {
      if (this.disabled) {
        // this.$messages('您没有权限', 'warning');
        return false;
      }
      if (typeof this.buttonCallback === 'function') {
        this.buttonCallback(type, e);
      }
    }
  },
  watch: {
    text (value) {
      return value;
    },
    width (value) {
      return value;
    },
    height (value) {
      return value;
    },
    type (value) {
      return value;
    }
  }
}
</script>

<style scoped>
  button {
    min-width: 70px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    padding: 0 10px;
    background-color: #51b3fe;
    color: #fff;
    border: 1px solid #51b3fe;
    outline: none;
    border-radius: 2px;
  }

  button:hover {
    background-color: #4aa4e9;
    color: #fff;
    border: 1px solid #4aa4e9;
  }

  button.disabled {
    color: #fff;
    background-color: #a0cfff;
    border-color: #a0cfff;
  }

  button.disabled:hover {
    cursor: not-allowed !important;
  }
</style>
