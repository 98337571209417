const user = resolve =>
	require(["../views/system/user/user.vue"], resolve);

const role = resolve =>
	require(["../views/system/role/role.vue"], resolve);

// 振动设备管理
const vibrationEquipment = resolve =>
	require(["../views/system/vibrationEquipment/equipmentManagement.vue"], resolve);
// 变形设备管理
const deformationEquipment = resolve =>
	require(["../views/system/deformationEquipment/equipmentManagement.vue"], resolve);

// 变形设备管理定制
const deformationEquipmentCustom = resolve =>
	require(["../views/system/deformationEquipmentCustom/equipmentManagement.vue"], resolve);

export default [{
		path: "/user",
		name: "user",
		component: user,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2, //顶部菜单类型
		}
	},
	{
		path: "/role",
		name: "role",
		component: role,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2, //顶部菜单类型
		}
	},
	{
		path: "/vibrationEquipment",
		name: "vibrationEquipment",
		component: vibrationEquipment,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2, //顶部菜单类型
		}
	},
	{
		path: "/deformationEquipment",
		name: "deformationEquipment",
		component: deformationEquipment,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2, //顶部菜单类型
		}
	},
	{
		path: "/deformationEquipmentCustom",
		name: "deformationEquipmentCustom",
		component: deformationEquipmentCustom,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2, //顶部菜单类型
		}
	},
];
