<template>
	<div class="historyStaPage pageContainer">
		<div class="pageMenu">
			<ul class="nav no-select-text">
				<li class="selected">测点数据</li>
				<li v-show="showRelevance" @click="switchNavMenu('HistoryExp')">关联数据</li>
			</ul>

			<Button type="search" :button-callback="buttonCallback" text="查询" />

			<div class="screen time">
				<span>时段：</span>
				<el-date-picker v-model="form.timeS" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
				<span>至</span>
				<el-date-picker v-model="form.timeE" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
			</div>
			<div class="screen selection">
				<!--  -->
				<el-select v-model="form.pid" filterable clearable placeholder="请选择" @change="getDeviceFile">
					<el-option v-for="item in pointList" :key="item.id" :label="item.name" :value="item.id">
						<el-tooltip :content="item.address" effect="light" placement="right">
							<span>{{item.name}}</span>
						</el-tooltip>
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="tableHeader no-select-text">
			<span style="width: 5%;"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="selectAllDevice">全选</el-checkbox></span>
			<span style="width: 4%;text-align: left;">序号</span>
			<span style="width: 18%;text-align: center;">文件名</span>
			<span style="width: 17.5%;">
				<em>幅值（X）</em>
				<em>主频（X）</em>
			</span>
			<span style="width: 17.5%;">
				<em>幅值（Y）</em>
				<em>主频（Y）</em>
			</span>
			<span style="width: 17.5%;">
				<em>幅值（Z）</em>
				<em>主频（Z）</em>
			</span>
			<span style="width: 7%; text-align: left;">报警值</span>
			<span style="width: 3%;">预警</span>
			<span style="width: 10%;text-align: center;text-indent: 0">编辑</span>
		</div>

		<div class="tableContainer">
			<div class="tableContent">
				<noData v-if="!item.total" />
				<el-scrollbar :native="false" :noresize="false" tag="div" v-if="item.total">
					<ul :class="relationId == p.id ? 'tableList activeList' : 'tableList'" v-for="(p, i) in item.rows" :key="i">
						<el-checkbox-group v-model="checkedDevice" @change="selectDevice($event, p)">
							<li class="no-select-text select-checkbox" style="width: 5%;"><el-checkbox :label="p.id"></el-checkbox></li>
							<li style="width: 4%;">{{ getListNumber(i, item) }}</li>
							<li style="width: 18%;" :title="timeHandle(p.fileTime, p.suffix)">{{ timeHandle(p.fileTime, p.suffix) }}</li>
							<li style="width: 17.5%;">
								<em :title="toFixed(p.xmax, 4) + p.unit">{{ toFixed(p.xmax, 4) }}{{ p.zmax !== '-' ? p.unit : '' }}</em>
								<em :title="p.xfqc ? toFixed(p.xfqc, 1) + 'Hz' : '0Hz'">{{ p.xfqc ? toFixed(p.xfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 17.5%;">
								<em :title="toFixed(p.ymax, 4) + p.unit">{{ toFixed(p.ymax, 4) }}{{ p.ymax !== '-' ? p.unit : '' }}</em>
								<em :title="p.yfqc ? toFixed(p.yfqc, 1) + 'Hz' : '0Hz'">{{ p.yfqc ? toFixed(p.yfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 17.5%;">
								<em :title="toFixed(p.zmax, 4) + p.unit">{{ toFixed(p.zmax, 4) }}{{ p.zmax !== '-' ? p.unit : '' }}</em>
								<em :title="p.zfqc ? toFixed(p.zfqc, 1) + 'Hz' : '0Hz'">{{ p.zfqc ? toFixed(p.zfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 7%;" :title="p.alert ? p.alert : '-'">{{ p.alert ? p.alert : '-' }}{{ p.alert !== '-' ? p.unit : '' }}</li>
							<li style="width: 3%; text-align: center;" :class="p.over ? 'red' : 'blue'">{{ p.over ? '是' : '否' }}</li>
							<li style="width: 10%;" class="compile">
								<i @click="preview(p)" title="预览数据" class="icon iconfont icon-yulan outline blue"></i>
								<!-- <i @click="removeList(p, i)" title="移出列表" class="el-icon-delete delete red"></i> -->
								<i @click="relevanceData(p, p.pointName)" title="关联数据" class="icon iconfont icon-guanlian green"></i>
							</li>
						</el-checkbox-group>
					</ul>
				</el-scrollbar>
			</div>

			<div class="tableFooter">
				<div>
					<Button class="downloadTable" type="downloadTable" :button-callback="buttonCallback" text="下载表单" />
					<Button class="downloadData" type="downloadData" :button-callback="buttonCallback" text="下载数据" />
				<!-- 	<Button class="createWord" type="save" :button-callback="buttonCallback" text="生成报告" />
					<Button class="createWord" type="preview" :button-callback="buttonCallback" text="预览报告" /> -->
					<Button class="deleteData" type="deleteData" :button-callback="buttonCallback" text="删除数据" />
				</div>

				<div style="width: 530px;">
					<div class="sizeBox">
						<span>单页显示条数</span>
						<input type="number" v-model="changeSize" autocomplete="off" />
					</div>
					<div class="pagination-box"><Pagination :item="item" :current-page-change="currentPageChange"></Pagination></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import historySta from './historySta.js';

export default historySta;
</script>

<style scoped lang="scss">
@import './historySta.scss';
</style>

<style lang="scss">
.historyStaPage {
	.tableFooter {
		position: relative;

		.pagination-box {
			display: inline-block;

			.tableFooter.no-select-text {
				top: 0;
			}

			.pageNumber {
				margin-top: 5px;
			}

			.gotoPage {
				margin-top: 8px;
			}
		}
	}
}
</style>
