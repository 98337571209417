<template>
	<div id="app">
		<nav-menu v-if="headerShow"></nav-menu>
		<div :class="$route.meta.headerShow ? 'bigview-box height-s' : 'bigview-box height-l'"><router-view /></div>
	</div>
</template>

<script>
import navMenu from './components/navMenu.vue';
export default {
	name: '',
	data() {
		return {};
	},
	created() {},
	mounted() {},
	computed: {
		headerShow() {
			//顶部状态栏是否显示
			return this.$store.state.headerShow;
		}
	},
	methods: {},
	components: {
		navMenu
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style lang="scss">
html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

#app {
	font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bigview-box {
	width: 100%;
}

.height-s {
	height: calc(100% - 61px);
}

.height-l {
	height: 100%;
}

p {
	padding: 0;
	margin: 0;
}

a,
input,
button {
	outline: none;
}

strong {
	margin: 3px;
	color: #409eff;
}

.pointer {
	cursor: pointer;
}

/* 设置滚动条的样式 */
.scroll-style {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	/* 滚动槽 */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(144, 147, 153, 0.3);
		background: rgba(235, 239, 248, 1.0);
		border-radius: 10px;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(191, 193, 197, 1.0);
		-webkit-box-shadow: inset 0 0 6px rgba(144, 147, 153, 0);
	}

	::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(144, 147, 153, 0.5);
	}
}

// el加载框样式
.el-loading-mask {
	z-index: 35000 !important;
}

// 更改消息提示z-index
.el-message {
	z-index: 100000 !important;

	.el-message__closeBtn {
		color: #000000;
		font-weight: 600;
	}
}

// 更改输入框左右内边距
.el-input__inner {
	padding: 0 10px;
}

// 更改提示弹框位置
.el-message {
	top: 80px !important;
}

// 去除高德地图logo和文字
.amap-logo,
.amap-copyright {
	display: none !important;
}

/* 单行文本超出隐藏 */
.text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/* 多行文本超出隐藏 */
.texts-overflow {
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}

/* 消除浏览器输入框自动填充的背景色 */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	box-shadow: inset 0 0 0 1000px #fff;
}

/* 消除登陆时浏览器输入框自动填充的背景色 */
.login-input input:-webkit-autofill,
.login-input textarea:-webkit-autofill,
.login-input select:-webkit-autofill {
	box-shadow: inset 0 0 0 1000px #d2d5d8;
}


// 禁止文本域缩放
textarea {
	resize: none !important;
}

// 大图预览组件
.el-image-viewer__wrapper {
	z-index: 99999999 !important;
}

/* 改写的弹窗遮罩 */
.Mask-box {
	width: 100%;
	min-height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
}

// 盒子页面居中
.box-center {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 2px;
}

// 弹窗标题
.pop-ups-title {
	width: calc(100% - 40px);
	// height: 35px;
	// line-height: 35px;
	padding: 15px 20px;
	font-size: 16px;
	text-align: left;
	background-color: #609dbf;
	color: #ffffff;
	position: relative;
	border-radius: 2px 2px 0 0;

	.close {
		position: absolute;
		top: 19px;
		right: 15px;
		color: #ffffff;
		font-size: 14px;
	}
}

// 弹窗内容盒子
.pop-ups-content {
	border-radius: 0 0 2px 2px;
}

// el-dialog弹窗顶部样式更改
.el-dialog {
	.el-dialog__header {
		background-color: #609dbf;
		color: #ffffff;
		padding: 15px 20px;

		.el-dialog__title {
			color: #ffffff;
		}

		.el-dialog__headerbtn {
			font-size: 25px;
			top: 10px;

			i {
				color: #ffffff;
			}
		}
	}
}

/* MessageBox 弹框按钮顺序更改 */
.el-message-box__btns {
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;
}
.el-message-box__btns button:nth-child(1) {
	margin-left: 15px;
}
/* MessageBox 弹框按钮颜色更改 */
// 确定
.confirmButton {
	background: #fff !important;
	border: 1px solid #dcdfe6 !important;
	color: #606266 !important;
}
// 取消
.cancelButton {
	background: #66b1ff !important;
	border-color: #66b1ff !important;
	color: #fff !important;
}
/* MessageBox 提示图标大小更改 */
.icon-big {
	.el-message-box__status {
		font-size: 38px !important;
	}
}

// 百度地图隐藏左下角的标识
.anchorBL,
.BMap_cpyCtrl {
	display: none;
}
</style>
