<!--时间插件-->
<template>
  <div class="timeDiv">
    <p @click="showLayer" :class="clazz" :type="type" :data-format="format">{{showDate}}</p>
  </div>
</template>

<script>
  export default {
    name: "selectDate",
    props: {
      dateCallback: {
        type: Function,
        required: false
      },
      type: String,
      format: String,
      value: String
    },
    data() {
      return {
        clazz: 'date' + new Date().getTime() + parseInt(Math.random() * 100000),
        showDate: ''
      }
    },
    created() {
      let now = new Date().getTime();
      this.showDate = this.value ? this.value : new Date(now).format(this.format);
    },
    methods: {
      //显示时间弹窗
      showLayer: function (e) {
        e.stopPropagation();
        if(e.clientX - e.offsetX + 280 > window.innerWidth){
          this.left = e.clientX - e.offsetX - 279 + e.target.clientWidth
        }else{
          this.left = e.clientX - e.offsetX - 1;
        }
        if (e.clientY + e.target.clientHeight + 282 > window.innerHeight) {
          this.top = e.clientY - e.offsetY + e.target.clientHeight + 2 - 342;
        } else {
          this.top = e.clientY - e.offsetY + e.target.clientHeight + 2;
        }

        $('#timeLayer').remove();
        let text = this.timestamp($(e.currentTarget).text());
        this.date = new Date(text);
        this.year = this.yearT = this.date.getFullYear();
        this.month = this.monthT = parseInt(this.date.getMonth() + 1);
        this.day = this.dayT = this.date.getDate();
        let date = this.setDate();
        let btn = this.setHtmlBtn();
        let header = this.setHeader(this.year, this.month);
        let time = this.setTime();
        let Box = '<div id="timeLayer" style="left: ' + this.left + 'px; top: ' + this.top + 'px;">' + header + '' + date + '' + (time ? time : '') + '' + btn + '</div>';
        $('body').append(Box).click(function () {
          $('#timeLayer').fadeOut(500).remove();
        });

        this.box = $('#timeLayer');
        this.cut = $('#timeLayer .header .btn');
        this.list = $('#timeLayer .tableBox li');
        this.btn = $('#timeLayer .footerBtn span.btn');
        this.time = $('#timeLayer .timeBox ul ol li');
        this.setItem();
        this.cut.on('click', $.proxy(this.cutDate, this));
        this.list.on('click', $.proxy(this.selectList, this));
        this.btn.on('click', $.proxy(this.clickFooterBtn, this));
        this.time.on('click', $.proxy(this.selectTimes, this));
        this.box.fadeIn(500).click(function (e) {
          e.stopPropagation();
        });
      },
      //设置弹出框顶部时间
      setHeader: function (year, month) {
        let len = this.format.split('-').length;
        return '<div class="header">\n' +
          '        <i class="perv_y btn el-icon-d-arrow-left"></i>\n' +
          (len === 3 ? '<i class="perv_m btn el-icon-arrow-left"></i>' : '') +
          '        <div class="set_time">\n' +
          '            <span class="year" data-date="' + parseInt(year) + '">' + parseInt(year) + '年</span>\n' +
          (len > 1 ? '<span class="month" data-date="' + parseInt(month) + '">' + parseInt(month) + '月</span>' : '') +
          '        </div>\n' +
          (len === 3 ? '<i class="next_m btn el-icon-arrow-right"></i>' : '') +
          '        <i class="next_y btn el-icon-d-arrow-right"></i>\n' +
          '    </div>' +
          (len === 3 ? '<ul class="tableHeader">\n' +
            '        <li class="weekend">日</li>\n' +
            '        <li class="working">一</li>\n' +
            '        <li class="working">二</li>\n' +
            '        <li class="working">三</li>\n' +
            '        <li class="working">四</li>\n' +
            '        <li class="working">五</li>\n' +
            '        <li class="weekend">六</li>\n' +
            '    </ul>' : '');
      },
      //时间切换
      cutDate: function (e) {
        e.stopPropagation();
        if ($(e.currentTarget).hasClass('perv_y')) {
          --this.year
        } else if ($(e.currentTarget).hasClass('next_y')) {
          ++this.year
        } else if ($(e.currentTarget).hasClass('perv_m')) {
          if (this.month == 1) {
            --this.year;
            this.month = 12;
          } else {
            --this.month
          }
        } else if ($(e.currentTarget).hasClass('next_m')) {
          if (this.month == 12) {
            ++this.year;
            this.month = 1;
          } else {
            ++this.month
          }
        }
        this.list.removeClass();
        $('#timeLayer .header .set_time .year').attr('data-date', this.year).text(this.year + '年');
        $('#timeLayer .header .set_time .month').attr('data-date', this.month).text(this.month + '月');
        this.setItem();
      },
      //设置日期列表
      setDate: function () {
        let len = this.format.split('-').length;
        let list = '';
        if (len === 3) {
          for (let i = 0; i < 6; i++) {
            list += '<li class="weekend">1</li>\n' +
              '        <li class="working">1</li>\n' +
              '        <li class="working">1</li>\n' +
              '        <li class="working">1</li>\n' +
              '        <li class="working">1</li>\n' +
              '        <li class="working">1</li>\n' +
              '        <li class="weekend">1</li>';
          }
        } else if (len === 2) {
          list += '<li>一月</li><li>二月</li><li>三月</li><li>四月</li>' +
            '<li>五月</li><li>六月</li><li>七月</li><li>八月</li>' +
            '<li>九月</li><li>十月</li><li>十一月</li><li>十二月</li>';
        } else if (len === 1) {
          for (let i = 0; i < 15; i++) {
            list += '<li>1</li>'
          }
        }

        return '<ul class="tableBox type' + len + '">' + list + '</ul>';
      },
      //设置日期的具体时间
      setItem: function () {
        //设置当月日期
        let len_ = this.format.split('-').length,
          monthDay = this.getDay(this.year, parseInt(this.month)), //总共的数量
          prevDay = this.getDay(this.year, parseInt(this.month - 1)), //上一月天数
          firstWeek = this.getWeek(this.year + '/' + this.month + '/' + '01'), //开始的位置
          li = $('#timeLayer .tableBox li');
        if (len_ === 3) {
          if (this.month == 1) {
            prevDay = this.getDay(parseInt(this.year) - 1, parseInt(12))
          }
          for (let p = 0; p < firstWeek; p++) {
            p = Number(p);
            let str1 = '', month1 = '', day1 = '';
            if (this.month == 1) {
              day1 = parseInt((prevDay - firstWeek) + p + 1) < 10 ? '0' + parseInt((prevDay - firstWeek) + p + 1) : parseInt((prevDay - firstWeek) + p + 1);
              str1 = parseInt(this.year) - 1 + '-' + 12 + '-' + day1;
              str1 = this.dateFormat(str1);
            } else {
              month1 = (parseInt(this.month) - 1) < 10 ? '0' + (parseInt(this.month) - 1) : (parseInt(this.month) - 1);
              day1 = parseInt((prevDay - firstWeek) + p + 1) < 10 ? '0' + parseInt((prevDay - firstWeek) + p + 1) : parseInt((prevDay - firstWeek) + p + 1);
              str1 = this.year + '-' + month1 + '-' + day1;
              str1 = this.dateFormat(str1);
            }
            li.eq(p).addClass('prevMonth').attr('data-date', str1).text((prevDay - firstWeek) + p + 1);
          }

          this.day = this.day > monthDay ? this.day = monthDay : this.day;

          for (let j = 0; j < monthDay; j++) {
            j = Number(j);
            this.month = parseInt(this.month) < 10 ? '0' + parseInt(this.month) : parseInt(this.month);
            var day = (parseInt(j) + 1) < 10 ? '0' + (parseInt(j) + 1) : (parseInt(j) + 1);
            let str = this.dateFormat(this.year + '-' + this.month + '-' + day);

            if (this.day == j + 1) {
              li.eq(firstWeek + j).attr('data-date', str).addClass('this').text(j + 1);
            } else {
              li.eq(firstWeek + j).attr('data-date', str).text(j + 1);
            }
          }
          let len = 42 - monthDay - firstWeek;
          for (let n = 0; n < len; n++) {
            n = Number(n);
            var str2 = '', day2 = '', month2 = '';
            if (this.month == 12) {
              day2 = (parseInt(n) + 1) < 10 ? '0' + (parseInt(n) + 1) : (parseInt(n) + 1);

              str2 = parseInt(this.year) + 1 + '-' + 1 + '-' + day2;
              str2 = this.dateFormat(str2);
            } else {
              month2 = (parseInt(this.month) + 1) < 10 ? '0' + (parseInt(this.month) + 1) : (parseInt(this.month) + 1);
              day2 = (parseInt(n) + 1) < 10 ? '0' + (parseInt(n) + 1) : (parseInt(n) + 1);

              str2 = this.year + '-' + month2 + '-' + day2;
              str2 = this.dateFormat(str2);
            }
            li.eq(monthDay + firstWeek + n).attr('data-date', str2).addClass('nextMonth').text(n + 1);
          }
        } else if (len_ === 2) {
          li.each((k, v) => {
            let month = (parseInt(k) + 1) < 10 ? '0' + (parseInt(k) + 1) : (parseInt(k) + 1);
            let str = this.year + '-' + month;
            $(v).attr('data-date', str);
            if (this.month == k + 1) {
              $(v).addClass('this');
            }
          })
        } else if (len_ === 1) {
          let start = parseInt(this.year) - 7;
          for (let i = 0; i < 15; i++) {
            i = Number(i);
            li.eq(i).attr('data-date', start + i).text(start + i + '年');
            if (this.year == start + i) {
              li.eq(i).addClass('this')
            }
          }
        }
      },
      //设置时间的滚动条位置
      setScroll: function () {
        $('#timeLayer .timeBox ul ol').each(function (k, v) {
          let index = $(v).find('li.this').index();
        })
      },
      //设置弹出框选择时间
      setTime: function () {
        let hourl = '', minutel = '', secondl = '';
        let format = this.format.split(' ')[1];
        if (!format) return false;
        let time = format.split(':');
        let hourT = this.date.getHours();
        let minuteT = this.date.getMinutes();
        let secondT = this.date.getSeconds();


        for (let h = 0; h < 24; h++) {
          if (time[0] !== undefined && time[0] != '') {
            if (hourT == h) {
              hourl += '<li class="this">' + (h < 10 ? '0' + h : h) + '</li>'
            } else {
              hourl += '<li>' + (h < 10 ? '0' + h : h) + '</li>'
            }
          } else {
            hourl += '<li>' + (h < 10 ? '0' + h : h) + '</li>'
          }
        }
        for (let m = 0; m < 60; m++) {
          if (time[1] !== undefined && time[1] != '') {
            if (minuteT == m) {
              minutel += '<li class="this">' + (m < 10 ? '0' + m : m) + '</li>'
            } else {
              minutel += '<li>' + (m < 10 ? '0' + m : m) + '</li>'
            }
          } else {
            minutel += '<li>' + (m < 10 ? '0' + m : m) + '</li>'
          }
        }
        for (let s = 0; s < 60; s++) {
          if (time[2] !== undefined && time[2] != '') {
            if (secondT == s) {
              secondl += '<li class="this">' + (s < 10 ? '0' + s : s) + '</li>'
            } else {
              secondl += '<li>' + (s < 10 ? '0' + s : s) + '</li>'
            }
          } else {
            secondl += '<li>' + (s < 10 ? '0' + s : s) + '</li>'
          }

        }
        let hour = '   <ul>\n' +
          '            <li>\n' +
          '                <p>时</p>\n' +
          '                <ol class="timeScroll">' + hourl + '</ol>\n' +
          '            </li>\n' +
          '        </ul>\n',
          minute = '   <ul>\n' +
            '            <li>\n' +
            '                <p>分</p>\n' +
            '                <ol class="timeScroll">' + minutel + '</ol>\n' +
            '            </li>\n' +
            '        </ul>\n',
          second = '   <ul>\n' +
            '            <li>\n' +
            '                <p>秒</p>\n' +
            '                <ol class="timeScroll">' + secondl + '</ol>\n' +
            '            </li>\n' +
            '        </ul>\n';
        let html = '';

        if (format !== undefined && format !== '') {
          let len = time.length;
          if (len === 1) {
            html = '<div class="timeBox ul1" hidden>' + hour + '</div>'
          } else if (len == 2) {
            html = '<div class="timeBox ul2" hidden>' + hour + '' + minute + '</div>'
          } else if (len == 3) {
            html = '<div class="timeBox ul3" hidden>' + hour + '' + minute + '' + second + '</div>'
          }
          return html;
        }
      },
      //设置弹出框按钮
      setHtmlBtn: function () {
        let time = this.format.split(' ')[1];
        let timeBtn = '';
        if (time !== undefined && time !== '') {
          timeBtn = '<span class="timeBtn btn">选择时间</span>'
        }
        return '<div class="footerBtn">\n' + timeBtn +
          '        <span class="btn now">现在</span>\n' +
          '        <span class="btn confirm">确定</span>\n' +
          '    </div>';
      },
      //选择日期
      selectList: function (e) {
        e.stopPropagation();
        let date = $(e.currentTarget).attr('data-date');
        let time = '';
        let format = this.format.split(' ');
        $('#timeLayer .timeBox ul').each(function (k, v) {
          time += $(v).find('ol li.this').text() + ':'
        });
        if (format !== undefined && format !== '') {
          date += ' ' + time.substring(0, time.length - 1);
        }

        this.showDate = date;
        $('#timeLayer').fadeOut(500, function () {
          $(this).remove();
        });
        this.callBack(this.showDate)
      },
      //选择时间
      selectTimes: function (e) {
        e.stopPropagation();
        let _this = $(e.currentTarget);
        if (!_this.hasClass('this')) {
          _this.addClass('this').siblings().removeClass('this');
        }
      },
      //点击按钮
      clickFooterBtn: function (e) {
        e.stopPropagation();
        if ($(e.currentTarget).hasClass('timeBtn')) {
          this.setScroll();
          if ($(e.currentTarget).hasClass('show')) {
            $('#timeLayer .timeBox').fadeOut(300);
            $(e.currentTarget).removeClass('show').text('选择日期');
          } else {
            $('#timeLayer .timeBox').fadeIn(300, function () {
              // $('.timeScroll').getNiceScroll().resize();
              $('#timeLayer .timeBox ul ol').each(function (k, v) {
                let index = $(v).find('li.this').index();
                // $('.timeScroll').getNiceScroll(k).doScrollTop(index * 30);
              })
            });
            $(e.currentTarget).addClass('show').text('返回日期');
          }

        } else if ($(e.currentTarget).hasClass('now')) {
          let now = new Date().getTime();
          this.showDate = this.formatF(now, this.format);
          $('#timeLayer').fadeOut(500, function () {
            $(this).remove()
          });
          this.callBack(this.showDate)
        } else if ($(e.currentTarget).hasClass('confirm')) {
          let date = $('#timeLayer>ul.tableBox>li.this').attr('data-date');
          let time = '';
          let format = this.format.split(' ');
          $('#timeLayer .timeBox ul').each(function (k, v) {
            time += $(v).find('ol li.this').text() + ':'
          });
          if (format !== undefined && format !== '') {
            date += ' ' + time.substring(0, time.length - 1);
          }
          this.showDate = date;
          $('#timeLayer').fadeOut(500, function () {
            $(this).remove()
          });
          this.callBack(this.showDate)
        }
      },
      //格式化时间转为时间戳
      timestamp: function (date) {
        let time = date.split(' ')[1];
        let len_ = this.format.split('-').length;
        date = date.replace(/-/g, '/');
        if (len_ === 3) {
          if (time !== undefined && time !== '') {
            if (time.split(':').length === 1) {
              return new Date(date + ':00').getTime()
            }
            return new Date(date).getTime();
          } else {
            return new Date(date).getTime();
          }
        } else if (len_ === 2) {
          return new Date(date + '/01').getTime();
        } else {
          return new Date(date + '/01/01').getTime();
        }
      },
      //返回当前月的天数
      getDay: function (y, m) {
        return new Date(y, m, 0).getDate();
      },
      //d的格式为：2018-05-01；返回某月第一天是周几
      getWeek: function (d) {
        d = d.replace(/-/g, '/');
        return new Date(d).getDay()
      },
      //格式化时间
      dateFormat: function (date) {
        date = date.replace(/-/g, '/');
        return this.formatF(Date.parse(new Date(date)), this.format.split(' ')[0])
      },
      //格式化时间
      formatF: function (data, format) {
        let date = new Date(data);
        format = format === undefined ? 'yyyy-MM-dd hh:mm:ss' : format;
        let o = {
          "M+": date.getMonth() + 1, // month
          "d+": date.getDate(), // day
          "h+": date.getHours(), // hour
          "m+": date.getMinutes(), // minute
          "s+": date.getSeconds(), // second
          "q+": Math.floor((date.getMonth() + 3) / 3), // quarter
          "S": date.getMilliseconds()// millisecond
        };
        if (/(y+)/.test(format)) {
          format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
          if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
          }
        }
        return format;
      },
      //回调
      callBack: function (e) {
        if (typeof this.dateCallback === "function") {
          this.dateCallback({date: $.trim(e), type:this.type});
        }
      }
    },
  }
</script>

<style>
  .timeDiv {
    display: inline-block;
    width: auto;
    border: 1px solid #ccc;
    line-height: 26px;
    overflow: hidden;
    position: relative;
  }

  .timeDiv i {
    float: left;
    line-height: 26px;
    color: #4BB86E;
    margin-right: 10px;
  }

  .timeDiv p {
    float: left;
    cursor: pointer;
    position: relative;
    padding: 0 10px;
  }

  #timeLayer {
    font-size: 12px;
    position: absolute;
    top: 30px;
    left: 30px;
    width: 280px;
    height: 310px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(204, 204, 204, .8);
    border: 1px solid #eee;
    display: none;
    z-index: 9876543210;
  }

  #timeLayer .header {
    height: 45px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eee;
  }

  #timeLayer .header i {
    float: left;
    width: 30px;
    height: 30px;
    flex-grow: 0;
    margin-left: 10px;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: #999;
  }

  #timeLayer .header i:hover {
    background-color: #eee;
  }

  #timeLayer .header i.next_m,
  #timeLayer .header i.next_y {
    margin-left: 0;
    margin-right: 10px;
  }

  #timeLayer .header .set_time {
    flex-grow: 1;
    text-align: center;
    font-size: 14px;
  }

  #timeLayer .header .set_time span:first-child {
    display: inline-block;
    margin-right: 10px;
  }

  #timeLayer .tableHeader li {
    line-height: 40px !important;
  }

  #timeLayer > ul {
    overflow: hidden;
  }

  #timeLayer > ul > li {
    list-style: none;
    float: left;
    width: 39px;
    line-height: 30px;
    text-align: center;
  }

  #timeLayer li.prevMonth,
  #timeLayer li.nextMonth {
    color: #999 !important;
  }

  #timeLayer > ul > li.weekend {
    color: #666;
  }

  #timeLayer .footerBtn {
    line-height: 45px;
    height: 45px;
    border-top: 1px solid #eee;
    text-align: right;
  }

  #timeLayer .footerBtn span.timeBtn {
    float: left;
    margin: 7px 10px;
  }

  #timeLayer .footerBtn span {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #eee;
    margin-right: 10px;
    box-sizing: border-box;
    background-color: #f4f4f4;
    cursor: pointer;
  }

  #timeLayer .timeBox {
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
    height: 220px;
    background-color: #fff;
    overflow: hidden;
  }

  #timeLayer .timeBox li {
    list-style: none;
    line-height: 30px;
  }

  #timeLayer .timeBox ul {
    float: left;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    border-right: 1px solid #eee;
  }

  #timeLayer .timeBox.ul1 ul {
    width: 100%;
  }

  #timeLayer .timeBox.ul2 ul {
    width: 50%;
  }

  #timeLayer .timeBox.ul3 ul {
    width: 33.33%;
  }

  #timeLayer .timeBox ul:last-child {
    border-right: 0;
  }

  #timeLayer .timeBox ul p {
    line-height: 35px;
    height: 35px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
  }

  #timeLayer .timeBox ul ol {
    height: 185px;
    overflow-y: scroll;
  }

  #timeLayer .timeBox ol li {
    cursor: pointer;
  }

  #timeLayer > ul.tableBox > li:hover,
  #timeLayer .timeBox ol li:hover {
    background-color: #eee;
  }

  #timeLayer > ul.tableBox > li.select,
  #timeLayer .timeBox ol li.select {
    background-color: #48b16a;
    color: #fff;
  }

  #timeLayer > ul.tableBox > li {
    cursor: pointer;
  }

  #timeLayer .timeBox ol li.this,
  #timeLayer > ul.tableBox > li.this {
    background-color: #48b16a;
    color: #fff;
  }

  #timeLayer .timeBox {
    display: none;
  }

  #timeLayer > ul.type2 > li {
    width: 25%;
    margin: 21.5px 0;
  }

  #timeLayer > ul.type1 > li {
    width: 33.33%;
    margin: 6.9px 0;
  }
</style>
