export default {
	// type: download 生成图片并下载 url 生成图片并返回图片url
	install(Vue, options) {
		Vue.prototype.getImg = function(title, id, type = "download", complete=()=>{}) {
			const loading = this.$loading({
				lock: true,
				text: '图片生成中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0)'
			})
			this.html2canvas(document.getElementById(id)).then(function(canvas) {
				var image = new Image();
				var imgUrl = canvas.toDataURL("image/png");
				if (imgUrl == 'data:,') {
					alert('图片生成失败，请重试');
				} else {
					// image.src = imgUrl;
					// $('#img').attr('src', imgUrl);
					// $(image).appendTo($(".htmltarget"));
					if (type == "download") {
						var a = document.createElement('a'); // 创建一个a节点插入的document
						var event = new MouseEvent('click') // 模拟鼠标click点击事件
						a.id = 'download';
						a.download = title + ".png"; // 设置a节点的download属性值
						a.href = imgUrl; // 将图片的src赋值给a节点的href
						a.dispatchEvent(event) // 触发鼠标点击事件
						$('#download').remove();
					}else {}
					loading.close();
					complete(imgUrl);
				}
			});
		}
	}
}
