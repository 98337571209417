// 工具
const tool = resolve => require(["../views/tool/tool.vue"], resolve);
// 转发管理
const forwardingInfo = resolve => require(["../views/tool/forwardingManagement/index.vue"], resolve);
// 设备列表
const equipmentList = resolve => require(["../views/tool/equipmentList/equipmentList.vue"], resolve);
// 监测公司
const monitoringCompany = resolve => require(["../views/tool/monitoringCompany/monitoringCompany.vue"], resolve);
// 监管项目
const regulatoryItems = resolve => require(["../views/tool/regulatoryItems/regulatoryItems.vue"], resolve);
// 公司管理员
const administrator = resolve=> require(["../views/tool/administrator/administrator.vue"], resolve);
// 数据分析
const analysis = resolve=> require(["../views/tool/analysis/index.vue"], resolve);
// 设备管理
const equipment = resolve=> require(["../views/tool/equipment/equipment.vue"], resolve);
// 项目管理
const project = resolve=> require(["../views/tool/project/project.vue"], resolve);

// 用户操作日志
const userActionLog = resolve=> require(["../views/tool/userActionLog/userActionLog.vue"], resolve);

export default [{
		path: "/tool",
		name: "tool",
		component: tool,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/forwardingInfo",
		name: "forwardingInfo",
		component: forwardingInfo,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型		
		}
	},
	{
		path: "/tool/equipmentList",
		name: "equipmentList",
		component: equipmentList,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/monitoringCompany",
		name: "monitoringCompany",
		component: monitoringCompany,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/regulatoryItems",
		name: "regulatoryItems",
		component: regulatoryItems,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/administrator",
		name: "administrator",
		component: administrator,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/analysis",
		name: "analysis",
		component: analysis,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/equipment",
		name: "equipment",
		component: equipment,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/project",
		name: "project",
		component: project,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
	{
		path: "/tool/userActionLog",
		name: "userActionLog",
		component: userActionLog,
		meta: {
			headerShow: true, //顶部状态栏是否显示
			menuShow: true, //顶部菜单是否显示
			childBackShow: '工具',//顶部子页面返回按钮名称
			menuType: 2,//顶部菜单类型
		}
	},
]
